<template>
  <div>
    <b-button class="exit" @click="$vm2.open('exit_modal')" :disabled="!enabled">Abandon study</b-button>
    <vue-modal-2
      @on-close="$vm2.close('exit_modal')"
      style="z-index: 10"
      name="exit_modal"
      modalSize="md"
      :headerOptions="{
        title: 'Exit?',
      }"
      :footerOptions="{
        btn1: 'No',
        btn2: 'Yes',
        btn2Style: {
          backgroundColor: 'red',
          left: 0,
        },
        btn2OnClick: () => {
          goodbye();
        },
        btn1OnClick: () => {
          $vm2.close('exit_modal');
        },
      }"
    >
      <p class="exit_text" v-html="check_message()"></p>
    </vue-modal-2>
  </div>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import axios from 'axios'

export default {
  name: "exit_panel",
  mixins: [common],
  props: {
    screennum: {
      type: Number,
      required: false,
      default: 1
    }
  },
  data () {
    return {
      enabled: false,
      player_id: null
    }
  },
  methods: {
    check_message() {
      if (this.screennum == 1) {
        return this.$msg_exit1
      } else {
        return this.$msg_exit2
      }
    },
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    goodbye() {
      let currentRoute = this.$router.history.current.fullPath;
      if (currentRoute == '/consent') {
        const path_participants = 'send_to_db';
        axios.get(path_participants, {
            params: {
              table: 'Table_Participants',
              data: {
                Player_ID: [this.player_id],
                Consent: [0]
              }
            }
          })
          .then((res) => {
            res.status;
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
      }
        
      const path_consent = 'send_to_db';
      axios.get(path_consent, {
          params: {
            table: 'Table_Time',
            data: {
              Player_ID: [this.player_id],
              Exit: [this.getTime()]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
      this.$vm2.close('exit_modal');
      if (this.screennum > 1) {
        this.$router.push("/debriefing")
      } else {
        this.$router.push("/goodbye1")
      }
    }
  },
  created() {
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id != 'None') {
        this.enabled = true
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";


.exit_text {
  padding: 10px;
}


</style>
