var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',{staticStyle:{"padding":"10px"}},[_c('div',{staticClass:"message_box"},[_c('b-row',[_c('h3',{staticStyle:{"color":"blue"}},[_vm._v("LEVEL 1 - DAY 1: Anxiety")]),_c('h4',[_vm._v("While your transactions are being reported (or not) and further investigated…")]),_c('p',{staticStyle:{"color":"blue"}},[_vm._v("How anxious are you feeling?")]),_c('label',{attrs:{"for":"anxiety"}},[_vm._v("Select the most appropriate description from the dropdown box to indicate how anxious you are currently feeling:")]),_c('Dropdown',{attrs:{"options":[
              {'id': 1, 'name': '0 - Totally relaxed'},
              {'id': 2, 'name': '1'},
              {'id': 3, 'name': '2'},
              {'id': 4, 'name': '3'},
              {'id': 5, 'name': '4'},
              {'id': 6, 'name': '5'},
              {'id': 7, 'name': '6'},
              {'id': 8, 'name': '7'},
              {'id': 9, 'name': '8'},
              {'id': 10, 'name': '9'},
              {'id': 11, 'name': '10 - Unbearably anxious'}],"maxItem":11,"placeholder":"Select anxiety level","id":"anxienty"},on:{"selected":_vm.anxiety_option}})],1),_c('b-row',[_c('div',{staticStyle:{"width":"10%","min-width":"140px"},attrs:{"id":"id_proceed","disabled":!_vm.checkFilled(_vm.anxiety_level)}},[_c('b-button',{staticClass:"proceed",attrs:{"disabled":_vm.checkFilled(_vm.anxiety_level) || !_vm.enabled},on:{"click":_vm.proceed}},[_vm._v("Proceed")])],1),_c('b-tooltip',{attrs:{"target":"id_proceed","placement":"right"}},[_vm._v("Please select an anxiety level.")])],1),_c('b-row',[_c('exitpanel',{attrs:{"screennum":2}})],1)],1)]),_c('p',{staticStyle:{"color":"blue","position":"fixed","right":"3%","top":"3%"}},[_vm._v("Alias: "+_vm._s(this.$alias))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }