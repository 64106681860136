import { render, staticRenderFns } from "./3.6_Game2Intro.vue?vue&type=template&id=2f655bac&scoped=true&"
import script from "./3.6_Game2Intro.vue?vue&type=script&lang=js&"
export * from "./3.6_Game2Intro.vue?vue&type=script&lang=js&"
import style0 from "./3.6_Game2Intro.vue?vue&type=style&index=0&id=2f655bac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f655bac",
  null
  
)

export default component.exports