import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'vue-toast-notification/dist/theme-sugar.css';
import BootstrapVue from "bootstrap-vue";
import VueToast from 'vue-toast-notification';
import Modal from "@burhanahmeed/vue-modal-2";
import Icon from 'vue-awesome/components/Icon';
import Vue from 'vue';
import App from './App.vue';
import router from "./router";
import axios from 'axios';
import "@/styles/_main.scss";

import 'vue-awesome/icons/eye'
import 'vue-awesome/icons/eye-slash'

Vue.use(BootstrapVue);
Vue.use(Modal);
Vue.use(VueToast);
Vue.component('v-icon', Icon)
Vue.config.productionTip = false;
Vue.prototype.$alias = null;
Vue.prototype.$toSave = false;

require("dotenv").config();
console.log(process.env.VUE_APP_BASE_URL)
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

new Vue({
  router,
  render: h => h(App),
  created() {
    window.addEventListener('beforeunload', this.handler)
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.handler)
  },
  methods: {
    handler(event) {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = `Do you really want to leave?`;
      return 'Do you really want to leave?'
    }
  }
}).$mount("#app");
