<template>
  <b-container>
    <vue-modal-2
      @on-close="$vm2.close('confirm_pay')"
      style="z-index: 10"
      name="confirm_pay"
      modalSize="md"
      :headerOptions="pay_popup_title"
      :footerOptions="{
        btn1: 'No',
        btn2: 'Yes',
        btn2Style: {
          backgroundColor: 'red',
          left: 0,
        },
        btn2OnClick: () => {
          $vm2.close('confirm_pay')
          buy_option();
        },
        btn1OnClick: () => {
          $vm2.close('confirm_pay');
        },
      }"
    >
      <p class="modal_text" v-html="replace_vars_in_text(msg_buy_report)"></p>
    </vue-modal-2>

    <vue-modal-2
      @on-close="$vm2.close('confirm_proceed')"
      style="z-index: 10"
      name="confirm_proceed"
      modalSize="md"
      :headerOptions="{title: 'There is time!'}"
      :footerOptions="{
        btn1: 'Add more transactions',
        btn2: 'Proceed to results',
        btn1Style: {
          backgroundColor: 'grey',
          color: 'white',
          right: 0,
        },
        btn2Style: {
          backgroundColor: 'grey',
          left: 0,
        },
        btn2OnClick: () => {
          $vm2.close('confirm_proceed')
          disable_collapse(4);
        },
        btn1OnClick: () => {
          $vm2.close('confirm_proceed');
        },
      }"
    >
      <p class="modal_text" v-html="msg_proceed_step5"></p>
    </vue-modal-2>
    <b-row :key="refreshing">
      <h3>The Money Laundering Simulation Game</h3>
      <h5>(Familiarisation: Practice round)</h5>
      <b-collapse id='collapse-step-1' v-model="visibility[1]">
        <fieldset :disabled="disable_dict[1]">
          <div class="message_box">
            <b-row>
              <b-col cols="10">
                <h5>1) Scenario Information for Level 0:</h5>
                <p v-html="replace_vars_in_text(scenario_info)"></p>
                <b-button v-on:click="disable_collapse(1)" :disabled="!enabled">Proceed to Step 2</b-button>
              </b-col>
              <b-col>
                <!-- <h5>Time Remaining: <span style="color: red;">{{ convertMS(time_scenario) }}</span></h5> -->
              </b-col>
            </b-row>
          </div>
        </fieldset>
      </b-collapse>
      <b-collapse id='collapse-step-2' v-model="visibility[2]">
        <fieldset>
          <div class="message_box">
            <b-row>
              <b-col cols="10">
                <b-row>
                  <h5>2) Obtain Option Information:</h5>
                  <p v-html="replace_vars_in_text(msg_step2)"></p>
                </b-row>
                <b-row>
                  <p>
                    <u>For free/already purchased options to view:</u>
                  </p>
                  <table class="bordered" style="margin-left: 1%;">
                    <tr class="selected-option">
                      <th v-b-toggle.options-free  style="width: 30%;">
                        <p class="vert_align" style="text-align: center; color: green;">{{ free_option_selected.name }}</p>
                      </th>
                      <td style="background-color: white;">
                        <p class="vert_align" v-html="free_option_selected.description"></p>
                      </td>
                    </tr>
                    <b-collapse id='options-free'>
                      <div 
                        v-b-toggle.options-free
                        class="options-dropdown" 
                        v-for="item in free_options" 
                        @click="change_free_option(item)"
                        :key="item.id">
                        <p class="vert_align" style="text-align: center; margin-top: 0px; padding-top: 5px;"  v-html="item.name"></p>
                      </div>
                    </b-collapse>
                  </table>
                </b-row>
                <b-row>
                  <p>
                    <br><u>Options available to purchase on this level:</u>
                  </p>
                  <table class="bordered" style="margin-left: 1%; width: 30%">
                    <tr class="selected-option">
                      <th v-b-toggle.options-pay style="width: 30%;">
                        <p class="vert_align" style="text-align: center; color: green;">Buy Knowledge</p>
                      </th>
                    </tr>
                    <b-collapse id='options-pay'>
                      <div 
                        v-b-toggle.options-pay
                        class="options-dropdown" 
                        v-for="item in pay_options" 
                        @click="change_pay_option(item)"
                        :key="item.id">
                        <p class="vert_align" style="text-align: center; margin-top: 0px; padding-top: 5px;" >{{ item.name }}</p>
                      </div>
                    </b-collapse>
                  </table>
                </b-row>
                <p/>
                <b-button v-on:click="disable_collapse(2)" :disabled="disable_dict[2]">Proceed to Step 3</b-button>
              </b-col>
              <b-col>
                <!-- <h5>Time Remaining: <span style="color: red;">{{ convertMS(time_report) }}</span></h5> -->
              </b-col>
            </b-row>
          </div>
        </fieldset>
      </b-collapse>
      <b-collapse id='collapse-step-3' v-model="visibility[3]">
        <fieldset :disabled="disable_dict[3]">
          <div class="message_box">
            <b-row>
              <b-col cols="10">
                <h5>3) Negotiate Crime Options:</h5>
                <p v-html="replace_vars_in_text(msg_step3)"></p>
                <table class="table_border_style">
                  <tr>
                    <th style="width: 50%"><p class="vert_align"><u>Crime syndicate negotiations:</u></p></th>
                    <th style="width: 20%"></th>
                    <th style="width: 10%"></th>
                  </tr>
                  <tr>
                    <td><p  class="vert_align">Daily illegal cash proceeds:</p></td>
                    <td></td>
                    <td><p class="finances_table_right">{{ financial($all_variables['Var_illegalProceeds']) }}</p></td>
                  </tr>
                  <tr>
                    <td><p class="vert_align">Options to influence illegal cash proceeds:</p></td>
                    <td>
                      <div>
                        <Dropdown 
                          :options="proceed_options"
                          :id_selected="proceed_selected_id"
                          :key="proceed_selected_id"
                          :maxItem="20"
                          placeholder="Select proceed method..."
                          v-on:selected="illegal_proceeds_select"/>
                      </div>
                    </td>
                    <td><p class="finances_table_right">{{ financial(increase_illegal) }}</p></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td><p class="finances_table_right" style="border-top: 2px solid black;">{{financial(parseFloat($all_variables['Var_illegalProceeds']) + increase_illegal)}}</p></td>
                  </tr>
                  <tr>
                    <th><p class="vert_align"><u>Other negotiations and decisions:</u></p></th>
                    <th></th>
                    <th></th>
                  </tr>
                  <tr>
                    <td><p class="vert_align">Reports purchased</p></td>
                    <td></td>
                    <td><p class="finances_table_right">({{financial(total_report_costs)}})</p></td>
                  </tr>
                  <tr v-if="report_purchased('Front Company')">
                    <td><p class="vert_align">Number of Front Companies involved in today:</p></td>
                    <td>
                      <div>
                        <Dropdown 
                          :options="[
                            {'id': 1, 'name': '0'},
                            {'id': 2, 'name': '1'},
                            {'id': 3, 'name': '2'},
                            {'id': 4, 'name': '3'},]"
                          :maxItem="20"
                          :id_selected="front_selected_id"
                          :key="front_selected_id"
                          placeholder="Selecting..."
                          v-on:selected="front_copanies_select"/>
                      </div>
                    </td>
                    <td><p class="finances_table_right">({{financial(calc_front_companies())}})</p></td>
                  </tr>
                  <tr v-if="report_purchased('Jewellery ')">
                    <td><p class="vert_align">Planning to trade in jewellery today:</p></td>
                    <td>
                      <div>
                        <Dropdown 
                          :options="[
                            {'id': 1, 'name': 'No'},
                            {'id': 2, 'name': 'Yes'}]"
                          :maxItem="2"
                          :id_selected="jewel_selected_id"
                          :key="jewel_selected_id"
                          placeholder="Selecting..."
                          v-on:selected="jewelery_select"/>
                      </div>
                    </td>
                    <td><p class="finances_table_right">({{financial(calc_jewelery())}})</p></td>
                  </tr>
                  <tr>
                    <th><p class="vert_align"><strong>Money available for laundering:</strong></p></th>
                    <th></th>
                    <th><p class="finances_table_right" style="border-top: 2px solid black;">{{financial(calc_total_step3())}}</p></th>
                  </tr>
                </table>
                <p/>
                <b-button :disabled ="proceed_selected_id == 0" v-on:click="disable_collapse(3)">Proceed to Step 4</b-button>
              </b-col>
              <b-col>
                <!-- <h5>Time Remaining: <span style="color: red;">{{ convertMS(time_crime) }}</span></h5> -->
              </b-col>
            </b-row>
          </div>
        </fieldset>
      </b-collapse>
      <b-collapse id='collapse-step-4' v-model="visibility[4]">
        <fieldset :disabled="disable_dict[4]">
          <div class="message_box">
            <b-row>
              <b-col cols="10">
                <b-row>
                  <h5>4) Schedule Transactions (Money Laundering):</h5>
                  <p v-html="replace_vars_in_text(msg_step4)"></p>
                </b-row>
              </b-col>
              <b-col>
                <h5>Time Remaining: <span style="color: red;">{{ convertMS(time_launder) }}</span></h5>
              </b-col>
            </b-row>
            <b-row>
              <b-row>
                <table class="bordered transaction_table" style="margin-left:10px;">
                  <tr>
                    <th style="width=20%"><p class="vert_align" >From</p></th>
                    <th><p class="vert_align" >To</p></th>
                    <th><p class="vert_align" >Amount</p></th>
                    <th><p class="vert_align" >Cost</p></th>
                    <th><p class="vert_align" >Final Bank</p></th>
                    <th class="selected-option" v-if="show_reload()">
                      <div @click="reload_transactions()">
                        <p class="vert_align" style="color: blue; text-align: center;">RELOAD</p>
                      </div>
                    </th>
                  </tr>
                  <tr v-for="item in transactions" :key="item.id" class="selected-option">
                    <td style="background-color: white;"><p class="vert_align"> {{item.from}} </p></td>
                    <td style="background-color: white;"><p class="vert_align"> {{item.to}} </p></td>
                    <td style="background-color: white;"><p class="vert_align finances_table_right"> {{financial(item.amount)}} </p></td>
                    <td style="background-color: white;"><p class="vert_align finances_table_right"> ({{financial(item.cost)}}) </p></td>
                    <td style="background-color: white;"><p class="vert_align finances_table_right"> {{financial(item.final)}} </p></td>
                    <td v-if="item.id == transactions.length">
                      <div @click="delete_last_transaction(item.id)">
                        <p class="vert_align" style="color: red; text-align: center;">DELETE</p>
                      </div>
                    </td>
                  </tr>
                  <tr class="selected-option">
                    <th v-b-toggle.options-from ><p class="vert_align" v-bind:class="{ 'notSelected': isChosen(from) }"> {{from}} </p></th>
                    <th v-b-toggle.options-to ><p class="vert_align" v-bind:class="{ 'notSelected': isChosen(to) }"> {{to}} </p></th>
                    <th>
                      <input 
                        class="vert_align finances_table_right input_amount"
                        placeholder='Input Amount'
                        min="1" 
                        :max="from_max"
                        v-model="trans_amount"
                        @keypress="isNumber($event)"
                        :key="from_max"
                      >
                    </th>
                    <th style="background-color: white;"><p class="vert_align finances_table_right"> ({{financial(calc_transaction_cost())}}) </p></th>
                    <th style="text-align: right; border: 4px solid black; background-color: white;">{{ potential_final_bank() }}</th>
                    <th>
                      <div @click="add_transaction">
                        <p class="vert_align" style="color: green; text-align: center;">ADD</p>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <td style="border: none; vertical-align: top; padding:0px;">
                      <b-collapse id='options-from' style="width: 100%">
                        <div 
                          v-b-toggle.options-from
                          class="options-dropdown" 
                          v-for="item in transaction_options.filter(transact => transact.current > 0 && (transact.maxTX > 0 || transact.maxTX == null))" 
                          @click="change_from(item)"
                          :key="item.id">
                          <b-row class="fix-width-table">
                            <b-col cols="8">
                              <p class="vert_align" style="text-align: left; padding-left: 0px" >{{ item.name}}</p>
                            </b-col>
                            <b-col cols="4">
                              <p class="vert_align" style="text-align: right;" >{{ '(' + item.current + ')' }}</p>
                            </b-col>
                          </b-row>
                        </div>
                      </b-collapse>
                    </td>
                    <td style="border: none; vertical-align: top; padding:0px;">
                      <b-collapse id='options-to'>
                        <div 
                          v-b-toggle.options-to
                          class="options-dropdown" 
                          v-for="item in from_to_options"
                          @click="change_to(item)"
                          :key="item.id">
                          <b-row class="fix-width-table">
                            <b-col cols="8">
                              <p class="vert_align" style="text-align: left;" >{{ item.name}}</p>
                            </b-col>
                            <b-col cols="4">
                              <p class="vert_align" style="text-align: right;" >{{ '(' + item.current + ')' }}</p>
                            </b-col>
                          </b-row>
                        </div>
                      </b-collapse>
                    </td>
                    <td style="border: none; vertical-align: top; padding:0px;"></td>
                    <td style="border: none; vertical-align: top; padding:0px;"></td>
                    <td style="border: none; text-align: center; vertical-align: top;">
                      (If not confiscated, stolen or devalued)
                    </td>
                  </tr>
                <p/>
                </table>
              </b-row>
                <div>
                  <b-button v-on:click="check_condition_step4()">Proceed to Step 5</b-button>
                </div>
            </b-row>
          </div>
        </fieldset>
      </b-collapse>
      <b-collapse id='collapse-step-5' v-model="visibility[5]" :key="processed_results">
        <fieldset>
          <div class="message_box">
            <b-row>
              <b-col cols="10">
                <h5>5) Review Results:</h5>
                  <p/>
                  <p v-if="illegal_option_chosen == 'Accept Honest Job'" v-html="msg_honest"></p>
                  <p v-else-if="!crime_identified" v-html="msg_notidentified"></p>
                  <p v-else v-html="msg_confiscate_all"></p>
                  <table class="bordered">
                    <tr>
                      <td style="width:80%; padding:10px; text-align: left;">
                        <strong> Planned money to be available for laundering: </strong><br>
                        (after negotiations and purchasing of reports)
                      </td>
                      <td style="width:20%; text-align: center;">{{ financial(calc_total_step3()) }}</td>
                    </tr>
                    <tr>
                      <td style="width:80%; padding:10px; text-align: left;"><strong>Money transferred into Final Bank account as per your planning:</strong></td>
                      <td style="width:20%; text-align: center;">{{ financial(total_final_bank()) }}</td>
                    </tr>
                  </table>
                  <p/>
                  <p v-html="replace_vars_in_text(msg_step5)"></p>
                  <p/>
                <div v-if="
                  illegal_option_chosen != 'Accept Honest Job' &&
                  !crime_identified
                ">
                  <b-row>
                    <table class="bordered transaction_table" style="margin-left: 1%">
                      <tr>
                        <th style="text-align: center;"><p class="vert_align" >From</p></th>
                        <th style="text-align: center;"><p class="vert_align">To</p></th>
                        <th style="text-align: center;"><p class="vert_align">Transaction Amount</p></th>
                        <th style="text-align: center;"><p class="vert_align">Theft amount</p></th>
                        <th style="text-align: center;"><p class="vert_align">Valuation change</p></th>
                        <th style="text-align: center;"><p class="vert_align">Confiscated amount</p></th>
                      </tr>
                      <tr
                        v-for="item in transactions_deduction"
                        :key="item.id">
                        <td style="border-top: 0px; border-bottom: 0px; padding: 4px;">{{item.from}}</td>
                        <td style="border-top: 0px; border-bottom: 0px; padding: 4px;">{{item.to}}</td>
                        <td style="border-top: 0px; border-bottom: 0px; text-align: right;" >{{financial(item.transaction_amount)}}</td>
                        <td style="border-top: 0px; border-bottom: 0px; text-align: right;" >{{financial(item.theft_amount)}}</td>
                        <td style="border-top: 0px; border-bottom: 0px; text-align: right;" >{{financial(item.valuation_change)}}</td>
                        <td style="border-top: 0px; border-bottom: 0px; text-align: right;" >{{financial(item.confiscated_amount)}}</td>
                      </tr>
                      <tr style="border-top: 2px solid black;"><p/></tr>
                      <tr>
                        <td style="border: 0px;"></td>
                        <td style="border: 0px;"></td>
                        <td style="border: 0px; text-align: right;"><p class="vert_align" style="padding-right: 5px; font-weight: bold">Total:</p></td>
                        <td style="text-align: right;">{{financial(total_theft())}}</td>
                        <td style="text-align: right;">{{financial(total_valuation())}}</td>
                        <td style="text-align: right;">{{financial(total_confiscate())}}</td>
                      </tr>
                    </table>
                    <p v-if="transactions_deduction.length == 0"><br>None of your transactions were stolen, changed in value or were confiscated.</p>
                    <p/>
                  </b-row>
                  <p v-if="confiscated_final">{{ msg_confiscate_final }}</p>
                </div>
                <table>
                  <tr>
                    <td style="width: 60%">
                      <p><strong>Money in Final Bank account AFTER laundering - available for spending on your lifestyle and status:</strong></p>
                    </td>
                    <td class="centered" :class="determine_success()">
                      <p style="vertical-align: bottom; margin: 0px">{{ financial(clean_money_final) }}</p>
                    </td>
                  </tr>
                </table>
                <div 
                  class="centered"  
                  style="vertical-align: middle; margin-top: 10px">
                  <p style="font-size: 100%" :class="determine_success()" v-html="goal_comment">
                  </p>
                </div>
                <b-button v-on:click="disable_collapse(5)">Proceed to Level 1</b-button>
              </b-col>
              <b-col>
                <!-- <h5>Time Remaining: <span style="color: red;">{{ convertMS(time_results) }}</span></h5> -->
              </b-col>
            </b-row>
          </div>
        </fieldset>
      </b-collapse>
    </b-row>
    <exitpanel :screennum="2" />
  <p style="color:blue; position:fixed; right:3%; top:3%;">Alias: {{this.$alias}}</p>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import exitpanel from "@/components/exit_panel.vue";
import Dropdown from "@/components/dropdown.vue";
import axios from 'axios';

const initialData = () => ({
      player_id: null,
      enabled: false,
      player_group: 'All',
      scenario_info: null,
      level_restrictions: [],
      free_options: [],
      pay_options: [],
      transactions: [],
      transactions_deduction: [],
      transaction_options: [],
      trans_theft_random: null,
      trans_val_random: null,
      trans_conf_random: null,
      from_selected: {},
      to_selected: {},
      from_to_options: [],
      combo: '',
      from_max: 0,
      free_option_selected: {id: 1, name: '(View Knowledge)', description:'Description of Knowledge'},
      pay_option_selected: {},
      proceed_options: [],
      pay_popup_title: {},
      option_description: 'Description of number 1',
      total_report_costs: 0,
      increase_illegal: 0,
      illegal_option_chosen: '',
      illegal_option_crime: 0,
      nr_front_companies: 0,
      trade_jewelery: false,
      proceed_selected_id: 0,
      front_selected_id: 1,
      jewel_selected_id: 1,
      from: 'Select source',
      to: 'Select destination',
      trans_amount: null,
      msg_buy_report: '',
      msg_proceed_step5: '',
      msg_confiscate_all: '',
      msg_confiscate_final: '',
      msg_notidentified: '',
      msg_honest: '',
      msg_step2: '',
      msg_step3: '',
      msg_step4: '',
      msg_step5: '',
      crime_identified: false,
      confiscated_final: false,
      clean_money_final: 0,
      goal_comment: '',
      TimeName: '',
      time_scenario: 0,
      time_report: 0,
      time_crime: 0,
      time_launder: 0,
      time_results: 0,
      visibility: {
        1: true,
        2: false,
        3: false,
        4: false,
        5: false,
      },
      disable_dict: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      },
      existing_results: null,
      processed_results: false,
      refreshing: false,
      save: false,
      next: false,
      prev_transactions: [],
      prev_income: null,
      anxiety_completed: false
    })

export default {
  name: "MLPractiveLevel",
  mixins: [common],
  components: {
    exitpanel,
    Dropdown
  },
  data() {
    return initialData();
  },
  methods: {
    resetData(){
      const data = initialData()
      Object.keys(data).forEach(k => this[k] = data[k])
    },
    reload_transactions() {
      if ([this.prev_transactions].length > 0) {
        let transact_from = {}
        let transact_to = {}
        for (let res of this.prev_transactions) {
          this.transaction_options.map( function(obj) {
            if (obj['name'] == res['From']) {
              transact_from = obj
              transact_from.to.map( function(obj2) {
                if (obj2['name'] == res['To']) {
                  transact_to = obj2
                }
              })
            }
          })
          this.change_from(transact_from)
          this.change_to(transact_to)
          this.trans_amount = res.Amount
          this.trans_theft_random = res['Theft_Risk_Random']
          this.trans_val_random = res['Valuation_Risk_Random']
          this.trans_conf_random = res['Confiscation_Risk_Random']
          this.add_transaction()
        }
      }
    },
    show_reload() {
      if (this.day == 1 && this.level ==1) {
        return false
      } else if (this.transactions.length !== 0) {
        return false
      } else if (this.prev_income > this.calc_total_step3()) {
        return false
      } else if (this.prev_transactions.length == 0) {
        return false
      } else {
        return true
      }
    },
    total_theft() {
      let ammounts = this.transactions_deduction.reduce((cnt, obj) => cnt + obj.theft_amount, 0);
      return ammounts
    },
    total_valuation() {
      let ammounts = this.transactions_deduction.reduce((cnt, obj) => cnt + obj.valuation_change, 0);
      return ammounts
    },
    total_confiscate() {
      let ammounts = this.transactions_deduction.reduce((cnt, obj) => cnt + obj.confiscated_amount, 0);
      return ammounts
    },
    isChosen(val) {
      if (val.includes('Select')) {
        return true;
      } else {
        return false;
      }
    },
    start_timer(step) {
      if (this.$previous_route !== 'Admin') {
        if (step == 1) {this.timeoutTimer = setInterval(this.set_scenario_timeout, 1000); }
        else if (step == 2) { this.timeoutTimer = setInterval(this.set_report_timeout, 1000); }
        else if (step == 3) { this.timeoutTimer = setInterval(this.set_crime_timeout, 1000); }
        else if (step == 4) { this.timeoutTimer = setInterval(this.set_launder_timeout, 1000); }
        else if (step == 5) { this.timeoutTimer = setInterval(this.set_results_timeout, 1000); }
      }
    },
    set_scenario_timeout() {
      if(this.disable_dict[1]){
        clearInterval(this.timeoutTimer);
      }
      this.time_scenario += 1;
      this.save_time(this.time_scenario, 'Time_Step1')
    },
    set_report_timeout() {
      if(this.disable_dict[2]){
        clearInterval(this.timeoutTimer);
      }
      this.time_report += 1;
      this.save_time(this.time_report, 'Time_Step2')
    },
    set_crime_timeout() {
      if(this.disable_dict[3]){
        clearInterval(this.timeoutTimer);
      }
      this.time_crime += 1;
      this.save_time(this.time_crime, 'Time_Step3')
    },
    set_launder_timeout() {
      if(this.time_launder <= 0){
        clearInterval(this.timeoutTimer);
        this.disable_collapse(4)
        this.time_launder += 1
      }
      this.time_launder -= 1;
      this.save_time(this.time_launder, 'Time_Step4')
    },
    set_results_timeout() {
      if(this.disable_dict[5]){
        clearInterval(this.timeoutTimer);
      }
      this.time_results += 1;
      this.save_time(this.time_results, 'Time_Step5')
    },
    save_time(timeleft, step_column) {
      const path = 'send_to_db';
      let temp_data = { 
        Player_ID: [this.player_id],
        Group: [this.player_group],
        Level: [0],
        Day: [0]
      }
      temp_data[step_column] = ['00:' + this.convertMS(timeleft)]
      axios.get(path, {
          params: {
            table: 'Table_Player_Results',
            data: temp_data
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    populate_times() {
      this.save_time(this.time_scenario, 'Time_Step1')
      this.save_time(this.time_report, 'Time_Step2')
      this.save_time(this.time_crime, 'Time_Step3')
      this.save_time(this.time_launder, 'Time_Step4')
      this.save_time(this.time_results, 'Time_Step5')
    },
    determine_success() {
      if (this.clean_money_final >= this.$all_variables['Var_Goal']) {
        return 'success'
      } else {
        return 'failure'
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    check_max_amount() {
      if (this.trans_amount > this.from_max ) {
        this.trans_amount = this.from_max
        return true
      }
      return false
    },
    check_condition_step4() {
      if (this.total_final_bank() < this.$all_variables['Var_Goal'] && this.time_launder >= 15) {
        this.$vm2.open('confirm_proceed')
      }else {
        this.disable_collapse(4)
      }
    },
    disable_collapse(step) {
      if (step == 4 && !this.anxiety_completed) {
        this.$router.push("/practiceanxietylevel")
        this.anxiety_completed = true
        setTimeout(() => { this.visibility[5] = true }, 1000)
      } else {
        this.visibility[step+1] = true
      }
      setTimeout(() => {  window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight) }, 350);
      const path = 'send_to_db';
      let temp_data = [
        { Player_ID: [this.player_id], Group: [this.player_group], Level: [0], Day: [0], Step1_Complete: [1] },
        { Player_ID: [this.player_id], Group: [this.player_group], Level: [0], Day: [0], Step2_Complete: [1] },
        { Player_ID: [this.player_id], Group: [this.player_group], Level: [0], Day: [0], Step3_Complete: [1] },
        { Player_ID: [this.player_id], Group: [this.player_group], Level: [0], Day: [0], Step4_Complete: [1] },
        { Player_ID: [this.player_id], Group: [this.player_group], Level: [0], Day: [0], Step5_Complete: [1] }
      ]
      axios.get(path, {
          params: {
            table: 'Table_Player_Results',
            data: temp_data[step-1]
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      clearInterval(this.timeoutTimer);
      if (step !== 4 && step !== 3) {
        this.start_timer(step+1)
      }
      this.reset_time(step)
      this.save_vars(step)
      this.collect_new_vars(step)
      setTimeout(() => {  this.disable_dict[step] = true }, 100);
    },
    reset_time(step) {
      if (step == 1) this.time_scenario = 0
      else if (step == 2) this.time_report = 0
      else if (step == 3) this.time_crime = 0
      else if (step == 4) this.time_launder = 0
      else if (step == 5) this.time_results = 0
    },
    save_vars(step) {

      if (step == 3) {
        const path_send = 'send_to_db';
        axios.get(path_send, {
            params: {
              table: 'Table_Player_Results',
              data: {
                Player_ID: [this.player_id],
                Group: [this.player_group],
                Level: [0],
                Day: [0],
                Option_chosen: [this.illegal_option_chosen],
                Increase: [this.increase_illegal],
                Gross_income: (parseFloat(this.$all_variables['Var_illegalProceeds']) + this.increase_illegal),
                Deduct_for_Reports: [parseFloat(this.$all_variables['Var_ReportCost'])],
                Nr_Fronts_Chosen: [this.nr_front_companies],
                Deduct_for_Front_Co: [this.calc_front_companies()],
                Jewellery_Chosen: [+this.trade_jewelery],
                Deduct_for_Jewellery: [this.calc_jewelery()],
                Nett_income: [this.calc_total_step3()],
                Base_Crime_Risk_: [0],
                Increase_in_Crime_Risk: [0],
                Total_Crime_Risk: [0]
              }
            }
          })
          .then((res) => {
            res.status;
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
      } else if (step == 4) {
        if (this.$previous_route !== 'Admin') {
          const path_delete = 'delete_from_db'
            axios.get(path_delete, {
                params: {
                  data: {
                    table: 'Table_Player_Tx',
                    match_col: ['Player_ID'],
                    match_val: [this.player_id],
                    match_case: ['WHERE']
                  }
                }
              })
              .then((res) => {
                res.status;
                this.calculate_results()
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.error(error);
              });
        } else {
          this.calculate_results()
        }
      } else if (step == 5) {
        if (this.$previous_route !== 'Admin') {
          const path = 'send_to_db'
          axios.get(path, {
              params: {
                table: 'Table_Time',
                data: {
                  Player_ID: [this.player_id],
                  MLPracLevel: [this.getTime()]
                }
              }
            })
            .then((res) => {
              res.status;
              this.$router.push('/launderingintro')
            })
            .catch((error) => {
              // eslint-disable-next-line
              console.error(error);
            });
        } else {
          this.$router.push("/admin")
        }
      }
    },
    collect_new_vars(step) {
      if (step == 2) { this.get_proceed_options() }
      else if (step == 3) { this.get_transaction_vars() }
    },
    total_final_bank() {
      for (var a=0; a < this.transaction_options.length; a++) {
        for (var b=0; b < this.transaction_options[a].to.length; b++) {
          if (this.transaction_options[a].to[b].name == 'Final Bank') {
            return this.transaction_options[a].to[b].current
          }
        }
      }
      return 0
    },
    potential_final_bank() {
      if (this.to == 'Final Bank') {
        return this.financial(this.total_final_bank() + this.trans_amount - this.calc_transaction_cost())
      } else {
        return null
      }
    },
    financial(x) {
        if (x != null)
          return Number.parseFloat(x).toFixed(2);
        return ''
    },
    generate_random_number(lower=0, upper=100) {
      return Math.floor(Math.random() * Math.floor(upper-lower+1))+lower;
    },
    convertMS(value) {
      const sec = parseInt(value, 10); // convert value to number if it's string
      let minutes = Math.floor((sec) / 60); // get minutes
      let seconds = sec - (minutes * 60); //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}
      return minutes+':'+seconds; // Return is HH : MM : SS
    },
    change_option(v) {
      this.option_description = v.description
    },
    change_free_option(v) {
      this.free_option_selected = v
    },
    change_pay_option(v) {
      if (!this.disable_dict[2]) {
        this.pay_option_selected = v
        this.pay_popup_title = {
          title: 'Buy ' + v.name + '?'
        }
        this.$vm2.open('confirm_pay')
      }
    },
    change_from(v) {
      if (!this.disable_dict[4] && this.from != v.name) {
        this.from_selected = v
        this.from = v.name
        this.from_to_options = v.to.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        this.from_max = v.current
        this.to = 'Select destination'
        this.trans_amount = null
      }
    },
    change_to(v) {
      if (!this.disable_dict[4]) {
        this.to_selected = v
        this.to = v.name
        this.perc_cost = v.cost
        this.combo = v.combo
      }
    },
    buy_option() {
      let idx = this.pay_options.findIndex(x => x.id === this.pay_option_selected.id)
      this.pay_options.splice(idx, 1)
      this.free_options.push(this.pay_option_selected)
      this.total_report_costs += parseFloat(this.$all_variables['Var_ReportCost'])

      const path_send = 'send_to_db';
      axios.get(path_send, {
          params: {
            table: 'Table_Player_FreeReport',
            data: {
              Player_ID: [this.player_id],
              Level: [0],
              Day: [0],
              Options_Available: [this.pay_option_selected.name],
              Report_Cost: [parseFloat(this.$all_variables['Var_ReportCost'])]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    get_proceed_options() {
      const path = 'fetch_all_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_Proceeds'
            }
          }
        })
        .then((res) => {
          let result = JSON.parse(res.data.data);

          let FreeOptions = this.free_options.map(function(obj) {
            return obj['name'];
          });

          for (var i=0; i < result.length; i++) {
            if (FreeOptions.includes(result[i].Report))
              this.proceed_options.push({
                id: i+1,
                name: result[i].Option,
                turnover: result[i].Crime_turnover_influence,
                crime: result[i].Crime_risk_influence
              })
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    get_transaction_vars() {
      let fronts_use = [...Array(this.nr_front_companies).keys()]
      fronts_use = fronts_use.map(function(val){return ++val;});

      const path = 'fetch_all_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_Cost_Risks'
            }
          }
        })
        .then((res) => {
          let costs_risks = JSON.parse(res.data.data);
          axios.get(path, {
            params: {
              data: {
                table: 'Table_OptionCombos'
              }
            }
          })
          .then((res) => {
            this.transaction_options = []
            let result = JSON.parse(res.data.data);

            let FreeOptions = this.free_options.map(function(obj) {
              if (obj['name'].slice(-1) == ' ') {
                return obj['name'].slice(0, -1)
              } else if (obj['name'] == 'Smurfs'){
                return 'Smurf'
              } else if (obj['name'] == 'Professional Launderer'){
                return 'Professional'
              } else {
                return obj['name'];
              }
            });

            let exists = false
            let cnt = 1
            let method = {}
            let in_to = []
            for (var a=0; a < FreeOptions.length; a++) {
              for (var b=0; b < result.length; b++) {
                method = result[b]
                if (
                  method.From.includes(FreeOptions[a]) && 
                  (
                    (method.From.includes('Jewellery') && this.trade_jewelery) ||
                    (method.From.includes('Front Company') && fronts_use.includes(parseInt(method.From.slice(-1)))) ||
                    (!method.From.includes('Jewellery') && !method.From.includes('Front Company'))
                  ))
                  for (var c=0; c < FreeOptions.length; c++)
                    if (
                      method.To.includes(FreeOptions[c]) && 
                      (
                        (method.To.includes('Jewellery') && this.trade_jewelery) ||
                        (method.To.includes('Front Company') && fronts_use.includes(parseInt(method.To.slice(-1)))) ||
                        (!method.To.includes('Jewellery') && !method.To.includes('Front Company'))
                      ))
                      for (var d=0; d < costs_risks.length; d++)
                        if (costs_risks[d].Combo == method.Combo) {
                          exists = false
                          for (var e=0; e < this.transaction_options.length; e++) {
                            if (this.transaction_options[e].name == method.From){
                              exists = true
                              in_to = this.transaction_options[e].to.map(function(obj) {
                                return obj['name'];
                              });
                              if (!in_to.includes(method.To)) {
                                if (method.To == 'Cash') {
                                  this.transaction_options[e].to.push({
                                    id: this.transaction_options[e].to.length + 1,
                                    name: method.To,
                                    combo: method.Combo,
                                    cost: costs_risks[d].Cost,
                                    current: Math.round(this.calc_total_step3()),
                                    theft_risk: costs_risks[d].Theft_risk,
                                    valuation_risk_lower: costs_risks[d].Valuation_risk_lower,
                                    valuation_risk_upper: costs_risks[d].Valuation_risk_upper,
                                    confiscation_under: costs_risks[d].Confiscation_risk_under_threshold,
                                    confiscation_upper: costs_risks[d].Confiscation_risk_over_threshold
                                  })
                                } else {
                                  this.transaction_options[e].to.push({
                                    id: this.transaction_options[e].to.length + 1,
                                    name: method.To,
                                    combo: method.Combo,
                                    cost: costs_risks[d].Cost,
                                    current: 0,
                                    theft_risk: costs_risks[d].Theft_risk,
                                    valuation_risk_lower: costs_risks[d].Valuation_risk_lower,
                                    valuation_risk_upper: costs_risks[d].Valuation_risk_upper,
                                    confiscation_under: costs_risks[d].Confiscation_risk_under_threshold,
                                    confiscation_upper: costs_risks[d].Confiscation_risk_over_threshold
                                  })
                                }
                              }
                            }
                          }

                          if (!exists) {
                            if (method.From == 'Cash') {
                              this.transaction_options.push({
                                id: cnt,
                                name: method.From,
                                current: Math.round(this.calc_total_step3()),
                                maxTX: (costs_risks[d].Max_tx_per_day !== null) ? this.$all_variables[costs_risks[d].Max_tx_per_day] : null,
                                to: [{
                                  id: 1,
                                  name: method.To,
                                  combo: method.Combo,
                                  cost: costs_risks[d].Cost,
                                  current: 0.0,
                                  theft_risk: costs_risks[d].Theft_risk,
                                  valuation_risk_lower: costs_risks[d].Valuation_risk_lower,
                                  valuation_risk_upper: costs_risks[d].Valuation_risk_upper,
                                  confiscation_under: costs_risks[d].Confiscation_risk_under_threshold,
                                  confiscation_upper: costs_risks[d].Confiscation_risk_over_threshold
                                }],
                              })
                            } else if (method.To == 'Cash') {
                              this.transaction_options.push({
                                id: cnt,
                                name: method.From,
                                current: 0.0,
                                maxTX: (costs_risks[d].Max_tx_per_day !== null) ? this.$all_variables[costs_risks[d].Max_tx_per_day] : null,
                                to: [{
                                  id: 1,
                                  name: method.To,
                                  combo: method.Combo,
                                  cost: costs_risks[d].Cost,
                                  current: Math.round(this.calc_total_step3()),
                                  theft_risk: costs_risks[d].Theft_risk,
                                  valuation_risk_lower: costs_risks[d].Valuation_risk_lower,
                                  valuation_risk_upper: costs_risks[d].Valuation_risk_upper,
                                  confiscation_under: costs_risks[d].Confiscation_risk_under_threshold,
                                  confiscation_upper: costs_risks[d].Confiscation_risk_over_threshold
                                }],
                              })
                            } else {
                              this.transaction_options.push({
                                id: cnt,
                                name: method.From,
                                current: 0,
                                maxTX: (costs_risks[d].Max_tx_per_day !== null) ? this.$all_variables[costs_risks[d].Max_tx_per_day] : null,
                                to: [{
                                  id: 1,
                                  name: method.To,
                                  combo: method.Combo,
                                  cost: costs_risks[d].Cost,
                                  current: 0.0,
                                  theft_risk: costs_risks[d].Theft_risk,
                                  valuation_risk_lower: costs_risks[d].Valuation_risk_lower,
                                  valuation_risk_upper: costs_risks[d].Valuation_risk_upper,
                                  confiscation_under: costs_risks[d].Confiscation_risk_under_threshold,
                                  confiscation_upper: costs_risks[d].Confiscation_risk_over_threshold
                                }],
                              })
                            }
                            cnt += 1
                          }
                        }
              }
            }
            this.start_timer(4)
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    calculate_results() {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_Levels_Setup',
              columns: ['*'],
              match_col: ['Level', 'Group'],
              match_val: [0, 'All'],
              match_case: ['WHERE', 'AND']
            }
          }
        })
        .then((res) => {
          let result = JSON.parse(res.data.data)[0];
          let nett_impact = null
          let available_laundering = null

          // Crime
          let crime_random = null
          if (!this.save){
            crime_random = this.generate_random_number()/100
          } else {
            crime_random = this.existing_results['Crime_risk_Random']
          }
          let crime_risk = result.Base_CRIME_risk + this.illegal_option_crime
          if (crime_random <= crime_risk) {
            available_laundering = 0
            this.crime_identified = true
          } else {
            available_laundering = this.calc_total_step3()
            this.crime_identified = false
          }

          // Theft
          let theft_random = null
          let theft_amount = null

          // Valuation
          let valuation_risk_random = null
          let valuation_change = null

          // ML
          let ml_risk = result.Base_ML_risk
          let risk_adjustment = 0
          let final_risk = 0
          let confiscation_random = null
          let confiscated_amount = null
          let threshold_risk = null

          let threshold_amount = null
          if (!this.save){
            threshold_amount = this.generate_random_number(result.LOWER_Reporting_Threshold, result.UPPER_Reporting_Threshold)
          } else {
            threshold_amount = this.existing_results['Threshold_amount']
          }
          let arr_to = this.transactions.map(function(obj) {
            return obj.to
          });
          let unique_to = [...new Set(arr_to)]


          let player_results = {
            Player_ID: [this.player_id],
            Group: [this.player_group],
            Level: [0],
            Day: [0],
            Daily_proceeds_earned: [parseFloat(this.$all_variables['Var_illegalProceeds'])],
            Option_chosen: [this.illegal_option_chosen],
            Increase: [this.increase_illegal],
            Gross_income: [parseFloat(this.$all_variables['Var_illegalProceeds']) + this.increase_illegal],
            Deduct_for_Reports: [this.total_report_costs],
            Nr_Fronts_Chosen: [this.nr_front_companies],
            Deduct_for_Front_Co: [this.calc_front_companies()],
            Jewellery_Chosen: [+ this.trade_jewelery],
            Deduct_for_Jewellery: [this.calc_jewelery()],
            Nett_income: [this.calc_total_step3()],
            Base_Crime_Risk_: [result.Base_CRIME_risk],
            Increase_in_Crime_Risk: [this.illegal_option_crime],
            Total_Crime_Risk: [crime_risk],
            Crime_risk_Random: [crime_random],
            Identified_Crime: [+ this.crime_identified],
            Available_for_laundering: [available_laundering],
            Threshold_amount: [threshold_amount],
            Nr_used_Bank_acc_: [(this.filter_word(unique_to, "Bank")).filter(to => !to.includes("Final")).length],
            Nr_used_To_Cash: [this.filter_word(unique_to, "Cash").length],
            Nr_used_Smurfs: [(this.filter_word(unique_to, "Smurf")).filter(to => !to.includes("Overseas")).length],
            Nr_used_Bitcoin_Private_acc: [this.filter_word(unique_to, "Bitcoin Private").length],
            Nr_used_Bitcoin_acc: [(this.filter_word(unique_to, "Bitcoin")).filter(to => !to.includes("Private")).length],
            Nr_used_Hawaladars: [this.filter_word(unique_to, "Hawaladars").length],
            Nr_used_Overseas_Smurfs: [this.filter_word(unique_to, "Overseas Smurf").length],
            Nr_used_Jewellery_stores: [this.filter_word(unique_to, "Jewellery").length],
            Nr_used_Front_Co_s: [this.filter_word(unique_to, "Front Company").length],
            'Nr_used_Profess.': [this.filter_word(unique_to, "Professional").length],
            Tx_To_Final_Bank_: [this.filter_word(arr_to, "Final Bank").length],
            Tx_To_Cash: [this.filter_word(arr_to, "Cash").length],
            Tx_To_Bank: [(this.filter_word(arr_to, "Bank")).filter(to => !to.includes("Final")).length],
            Tx_To_Smurfs: [(this.filter_word(arr_to, "Smurf")).filter(to => !to.includes("Overseas")).length],
            Tx_To_Bitcoin: [(this.filter_word(arr_to, "Bitcoin")).filter(to => !to.includes("Private")).length],
            Tx_To_Hawaladar: [this.filter_word(arr_to, "Hawaladars").length],
            Tx_To_Overseas_Smurfs: [this.filter_word(arr_to, "Overseas Smurf").length],
            Tx_To_Jewellery: [this.filter_word(arr_to, "Jewellery").length],
            Tx_To_Front_Co: [this.filter_word(arr_to, "Front Company").length],
            'Tx_To_Profess.': [this.filter_word(arr_to, "Professional").length],
            Tx_Total: [0],
            Diff_Mechanisms: [0],
            Diff_Destinations: [0],
            Diff_Layering_Tx: [0]
          }
          let group = ''
          for (let key in player_results) {
            group = key.substring(0, 2)
            if (group == 'Nr' && player_results[key][0] > 0) {
              player_results.Diff_Mechanisms[0] += 1
              player_results.Diff_Destinations[0] += player_results[key][0]
            } else if (group == 'Tx') {
              if (key != 'Tx_To_Final_Bank_' && key != 'Tx_Total') {
                player_results.Diff_Layering_Tx[0] += player_results[key][0]
              } else if (key != 'Tx_Total') {
                player_results.Tx_Total[0] += player_results[key][0]
              }
            } 
          }

          risk_adjustment -= player_results.Diff_Mechanisms[0] * result.For_EACH_Diff_Mechanism
          risk_adjustment -= player_results.Diff_Destinations[0] * result.For_EACH_Diff_Destination
          risk_adjustment -= player_results.Diff_Layering_Tx[0] * result.For_EACH_Layering_Tx

          let cnt = 1
          // Per Transaction
          for (var i=0; i < this.transactions.length; i++) {
            confiscated_amount = null
            final_risk = null
            theft_random = null
            theft_amount = null
            valuation_risk_random = null
            valuation_change = null

            // Theft
            if (this.transactions[i].theft_risk != null) {
              if (this.save) {
                theft_random = this.transactions[i].theft_random
              } else {
                theft_random = this.generate_random_number()/100
              }
              if (theft_random <= this.transactions[i].theft_risk) {
                theft_amount = -this.transactions[i].amount
              }
            }

            // Valuation
            if (this.transactions[i].valuation_risk_upper != null && theft_amount == null) {
              if (this.save) {
                valuation_risk_random = this.transactions[i].valuation_random
              } else {
                valuation_risk_random = this.generate_random_number(this.transactions[i].valuation_risk_lower*100, this.transactions[i].valuation_risk_upper*100)/100
              }
              valuation_change = Math.round(this.transactions[i].amount * valuation_risk_random / 10) * 10
            }
            
            // ML
            if (this.transactions[i].amount < threshold_amount) {
              threshold_risk = this.transactions[i].confiscation_under
            } else {
              threshold_risk = this.transactions[i].confiscation_upper
            }
            if (this.illegal_option_chosen != 'Accept Honest Job' && this.transactions[i].confiscation_upper != null) {
              final_risk = ml_risk + threshold_risk + risk_adjustment
            }

            if (theft_amount == null && final_risk !== null) {
              if (this.transactions[i].confiscation_random !== null && this.save) {
                confiscation_random = this.transactions[i].confiscation_random
              } else {
                confiscation_random = this.generate_random_number()/100
              }
              if (confiscation_random <= final_risk) {
                if (valuation_change == null) {
                  confiscated_amount = -this.transactions[i].amount + this.transactions[i].cost
                } else {
                  confiscated_amount = -this.transactions[i].amount + this.transactions[i].cost - valuation_change
                }
              }
            }

            // Total
            if (theft_amount != null) {
              nett_impact = theft_amount
            } else if (confiscated_amount != null) {
              nett_impact = confiscated_amount
            } else {
              nett_impact = valuation_change
            }
            


            // Adding newly calculated data 
            this.transactions[i].threshold_amount = threshold_amount
            this.transactions[i].theft_risk_random = theft_random
            this.transactions[i].theft_amount = theft_amount
            this.transactions[i].valuation_risk_random = valuation_risk_random
            this.transactions[i].valuation_change = valuation_change
            this.transactions[i].base_ml_risk = result.Base_ML_risk
            this.transactions[i].threshold_option_risk = threshold_risk
            this.transactions[i].laundering_risk_adjustment = risk_adjustment
            this.transactions[i].final_confiscation_risk = final_risk
            this.transactions[i].confiscation_risk_random = confiscation_random
            this.transactions[i].confiscated_amount = confiscated_amount
            this.transactions[i].nett_impact = nett_impact

            if ((valuation_change !== null && valuation_change !== 0) || theft_amount !== null || confiscated_amount !== null) {
              this.transactions_deduction.push({
                id: cnt,
                from: this.transactions[i].from,
                to: this.transactions[i].to,
                transaction_amount: this.transactions[i].amount - this.transactions[i].cost,
                theft_amount: theft_amount,
                valuation_change: valuation_change,
                confiscated_amount: confiscated_amount
              })
              cnt += 1
            }
          }
          let temp_data = {
            Player_ID: [],
            Level: [],
            Day: [],
            Tx_Nr: [],
            From: [],
            To: [],
            Combo: [],
            Amount: [],
            Cost: [],
            Threshold_amount: [],
            Theft_Risk_Random: [],
            Theft_Amount: [],
            Valuation_Risk_Random: [],
            Valuation_change: [],
            Base_ML_Risk: [],
            Threshold__and_Option_Risk: [],
            Laundering_Risk_Adjustment: [],
            Final_Confiscation_Risk: [],
            Confiscation_Risk_Random: [],
            Confiscated_Amount: [],
            Nett_Impact: []
          }
          let val = null
          for (i=0; i < this.transactions.length; i++) {
            val = this.transactions[i]
            temp_data.Player_ID.push(this.player_id)
            temp_data.Level.push(0)
            temp_data.Day.push(0)
            temp_data.Tx_Nr.push(val.id)
            temp_data.From.push(val.from)
            temp_data.To.push(val.to)
            temp_data.Combo.push(val.combo)
            temp_data.Amount.push(val.amount)
            temp_data.Cost.push(val.cost)
            temp_data.Threshold_amount.push(val.threshold_amount)
            temp_data.Theft_Risk_Random.push(val.theft_risk_random)
            temp_data.Theft_Amount.push(val.theft_amount)
            temp_data.Valuation_Risk_Random.push(val.valuation_risk_random)
            temp_data.Valuation_change.push(val.valuation_change)
            temp_data.Base_ML_Risk.push(val.base_ml_risk)
            temp_data.Threshold__and_Option_Risk.push(val.threshold_option_risk)
            temp_data.Laundering_Risk_Adjustment.push(val.laundering_risk_adjustment)
            temp_data.Final_Confiscation_Risk.push(val.final_confiscation_risk)
            temp_data.Confiscation_Risk_Random.push(val.confiscation_risk_random)
            temp_data.Confiscated_Amount.push(val.confiscated_amount)
            temp_data.Nett_Impact.push(val.nett_impact)
          }

          let total_confiscated = this.transactions.map(function(obj) {
            return obj.confiscated_amount
          })
          total_confiscated = total_confiscated.reduce((a, b) => a + b, 0);
          let total_nett_impact = this.transactions.map(function(obj) {
            return obj.nett_impact
          })
          total_nett_impact = total_nett_impact.reduce((a, b) => a + b, 0);

          if (result.Confiscate_Final_Bank == 1 && total_confiscated < 0) {
            this.confiscated_final = true
            player_results.Confiscate_Final_Bank = [-this.total_final_bank() - total_nett_impact]
          } else {
            player_results.Confiscate_Final_Bank = [0]
          }

          this.clean_money_final = this.total_final_bank() + total_nett_impact + player_results.Confiscate_Final_Bank[0]
          player_results.Clean_Money_in_Final_Bank = [this.clean_money_final]

          if (this.clean_money_final >= this.$all_variables['Var_Goal'] && !this.crime_identified) {
            player_results.Achieved_Goal = [1]
            player_results.Winnings_ML = [5]
          } else {
            player_results.Achieved_Goal = [0]
            player_results.Winnings_ML = [0]
          }

          const path = 'fetch_from_db';
          axios.get(path, {
              params: {
                data: {
                  table: 'Table_Result_Comments',
                  columns: ['Comment', 'Confiscate_all_Level'],
                  match_col: ['Reached_Goal'],
                  match_val: player_results.Achieved_Goal,
                  match_case: ['WHERE']
                }
              }
            })
            .then((res) => {
              let comments_result = JSON.parse(res.data.data);
              if (player_results.Achieved_Goal[0] == 0) {
                comments_result = comments_result.filter((a)=>{if(a['Confiscate_all_Level']==result.Confiscate_Final_Bank){return a}});
              }
              let rand = this.generate_random_number(0, 2)
              this.goal_comment = comments_result[rand]['Comment']
            })
            .catch((error) => {
              // eslint-disable-next-line
              console.error(error);
          });
          this.goal_comment = ''

          const path_send = 'send_to_db';
            axios.get(path_send, {
                params: {
                  table: 'Table_Player_Results',
                  data: player_results
                }
              })
              .then((res) => {
                res.status;
                const path_send = 'send_to_db';
                  axios.get(path_send, {
                      params: {
                        table: 'Table_Player_Tx',
                        data: temp_data
                      }
                    })
                    .then((res) => {
                      res.status;
                      this.processed_results = true
                    })
                    .catch((error) => {
                      // eslint-disable-next-line
                      console.error(error);
                    });  
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.error(error);
              });    
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    filter_word(arr, word) {
      return arr.filter(i => i.includes(word))
    },
    report_purchased(report) {
      let FreeOptions = this.free_options.map(function(obj) {
            return obj['name'];
          });
      return (FreeOptions.includes(report))
    },
    calc_front_companies() {
      let sub_total = parseFloat(this.$all_variables['Var_illegalProceeds']) + this.increase_illegal
      return sub_total * this.nr_front_companies * parseFloat(this.$all_variables['Var_ProceedsLowerFront'])
    },
    calc_jewelery() {
      let sub_total = parseFloat(this.$all_variables['Var_illegalProceeds']) + this.increase_illegal
      if (this.trade_jewelery) {
        return sub_total * parseFloat(this.$all_variables['Var_ProceedsLowerJewellery'])
      } else {
        return 0
      }
    },
    calc_total_step3() {
      let sub_total = parseFloat(this.$all_variables['Var_illegalProceeds']) + this.increase_illegal
      let front_companies_cost = this.calc_front_companies()
      let jewelery_cost = this.calc_jewelery()
      return sub_total - this.total_report_costs - front_companies_cost - jewelery_cost
    },
    calc_transaction_cost() {
        if (this.to !== 'Select destination' & this.trans_amount !== null){
          return Math.round((this.trans_amount * this.perc_cost)/10)*10
        } else {
          return 0
        }
    },
    illegal_proceeds_select(v) {
      if (Object.keys(v).length > 0) {
        this.increase_illegal = parseFloat(this.$all_variables['Var_illegalProceeds']) * v.turnover
        if (v.name == 'Accept Honest Job') this.increase_illegal += this.$all_variables['Var_Honest']
        this.illegal_option_chosen = v.name
        this.illegal_option_crime = v.crime
        this.proceed_selected_id = v.id
      }
    },
    front_copanies_select(v) {
      if (Object.keys(v).length > 0) {
        this.nr_front_companies = v.id - 1
      }
    },
    jewelery_select(v) {
      if (Object.keys(v).length > 0) {
        this.trade_jewelery = (v.id == 2)
      }
    },
    add_transaction() {
      if (this.check_transaction_filled() && !this.disable_dict[4] && !this.check_max_amount()) {
        let final_bank = null
        if (this.to == 'Final Bank') {
          final_bank = this.total_final_bank() + (this.trans_amount - Math.round(this.calc_transaction_cost()))
        }
        this.transactions.push(
          {id: this.transactions.length + 1, 
          from: this.from, 
          to: this.to, 
          combo: this.combo,
          amount: this.trans_amount, 
          cost: Math.round(this.calc_transaction_cost()),
          final: final_bank,
          theft_risk: this.to_selected.theft_risk,
          theft_random: this.trans_theft_random,
          valuation_random: this.trans_val_random,
          confiscation_random: this.trans_conf_random,
          valuation_risk_lower: this.to_selected.valuation_risk_lower,
          valuation_risk_upper: this.to_selected.valuation_risk_upper,
          confiscation_under: this.to_selected.confiscation_under,
          confiscation_upper: this.to_selected.confiscation_upper,
          threshold_amount: null,
          theft_risk_random: null,
          theft_amount: null,
          valuation_risk_random: null,
          valuation_change: null,
          base_ml_risk: null,
          threshold_option_risk: null,
          laundering_risk_adjustment: null,
          final_confiscation_risk: null,
          confiscation_risk_random: null,
          confiscated_amount: null,
          nett_impact: null
          })

        var a = 0
        var b = 0
        if (this.to.includes('Jewellery')) {
          for (a=0; a < this.transaction_options.length; a++) {
            if (this.transaction_options[a].name == this.to) {
              this.transaction_options[a].current += Math.round(this.trans_amount) - Math.round(this.calc_transaction_cost())
            }
            for (b=0; b < this.transaction_options[a].to.length; b++) {
              if (this.transaction_options[a].to[b].name == this.to) {
                this.transaction_options[a].to[b].current += Math.round(this.trans_amount) - Math.round(this.calc_transaction_cost())
              }
            } 
          }
          this.to = 'Jewellery'
        }
        for (a=0; a < this.transaction_options.length; a++) {
          if (this.transaction_options[a].name == this.to) {
            this.transaction_options[a].current += Math.round(this.trans_amount) - Math.round(this.calc_transaction_cost())
          } else if (this.transaction_options[a].name == this.from) {
            this.transaction_options[a].current -= Math.round(this.trans_amount)
            if (this.transaction_options[a].maxTX !== null)
              this.transaction_options[a].maxTX -= 1
          }
          for (b=0; b < this.transaction_options[a].to.length; b++) {
            if (this.transaction_options[a].to[b].name == this.from) {
              this.transaction_options[a].to[b].current -= Math.round(this.trans_amount)
            } else if (this.transaction_options[a].to[b].name == this.to) {
              this.transaction_options[a].to[b].current += Math.round(this.trans_amount) - Math.round(this.calc_transaction_cost())
            }
          } 
        }
        this.from_selected = {}
        this.from = 'Select source'
        this.from_to_options = []
        this.from_max = 0
        this.to = 'Select destination'
        this.trans_amount = null
      }
    },
    delete_last_transaction() {
      if (!this.disable_dict[4]) {
        let temp_transact = this.transactions[this.transactions.length-1]
        this.transactions.splice(this.transactions.length-1, this.transactions.length)
        
        var b=0
        for (var a=0; a < this.transaction_options.length; a++) {
          if (this.transaction_options[a].name == temp_transact.to) {
            this.transaction_options[a].current -= Math.round(temp_transact.amount) - Math.round(temp_transact.cost)
          } else if (this.transaction_options[a].name == temp_transact.from) {
            this.transaction_options[a].current += Math.round(temp_transact.amount)
            if (this.transaction_options[a].maxTX !== null)
              this.transaction_options[a].maxTX += 1
          }
          for (b=0; b < this.transaction_options[a].to.length; b++) {
            if (this.transaction_options[a].to[b].name == temp_transact.from) {
              this.transaction_options[a].to[b].current += Math.round(temp_transact.amount)
            } else if (this.transaction_options[a].to[b].name == temp_transact.to) {
              this.transaction_options[a].to[b].current -= Math.round(temp_transact.amount) - Math.round(temp_transact.cost)
            }
          } 
        }
      }
    },
    check_transaction_filled() {
      if (this.from != 'Select source' && this.to != 'Select destination' && this.trans_amount !== null) {
        return true;
      } else {
        return false
      }
    },
    get_scenario_descript() {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_Levels_Setup',
              columns: ['*'],
              match_col: ['Level', 'Group'],
              match_val: [0, 'All'],
              match_case: ['WHERE', 'AND']
            }
          }
        })
        .then((res) => {
          let result = JSON.parse(res.data.data);
          this.scenario_info= result[0]['Level_scenario']
          this.level_restrictions = result[0]
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    get_options_and_descriptions() {
      const path_descript = 'fetch_from_db';
      axios.get(path_descript, {
          params: {
            data: {
              table: 'Table_OptionDescriptions',
              columns: ['Options_Available', 'Description'],
              match_col: ['Free', 'Free'],
              match_val: [0, 1],
              match_case: ['WHERE', 'OR']
            }
          }
        })
        .then((res) => {
          let Options = JSON.parse(res.data.data);
          const path_free = 'fetch_from_db';
          axios.get(path_free, {
              params: {
                data: {
                  table: 'Table_Player_FreeReport',
                  columns: ['Level', 'Day', 'Options_Available', 'Report_Cost'],
                  match_col: ['Player_ID'],
                  match_val: [this.player_id],
                  match_case: ['WHERE']
                }
              }
            })
            .then((res) => {
              let result = JSON.parse(res.data.data);
              let FreeOptions = result.map(function(obj) {
                return obj['Options_Available'];
              });
              let all_report_costs = result.filter((obj) => {
                return obj['Level'] == 0 && obj['Day'] == 0
              });
              all_report_costs = all_report_costs.map(function(obj) {
                return obj['Report_Cost'];
              });
              this.total_report_costs = all_report_costs.reduce((x, y) => x + y, 0);

              let name = ''
              for (var i=0; i < Options.length; i++) {
                name = Options[i]['Options_Available']
                name = name.replace(/ /g, '_')
                if (name.slice(name.length-1, name.length) == '_') name = name.slice(0, name.length-1)
                if (name == 'Professional_Launderer') name = 'Professional'
                if (this.level_restrictions[name] == 1) {
                  if (FreeOptions.includes(Options[i]['Options_Available'])) {
                    this.free_options.push({
                      id : i+1,
                      name: Options[i]['Options_Available'],
                      description: this.replace_vars_in_text(Options[i]['Description'])
                    })
                    this.free_option_selected = this.free_options[0]
                  } else {
                    this.pay_options.push({
                      id : i+1,
                      name: Options[i]['Options_Available'],
                      description: this.replace_vars_in_text(Options[i]['Description'])
                    })
                  }
                }
              }
            })
            .catch((error) => {
              // eslint-disable-next-line
              console.error(error);
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    get_player_group() {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_Participants',
              columns: ['Group'],
              match_col: ['Player_ID'],
              match_val: [this.player_id],
              match_case: ['WHERE']
            }
          }
        })
        .then((res) => {
          let result = JSON.parse(res.data.data);
          this.player_group = result[0]['Group']
          this.fetch_existing()
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    add_free_reports() {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_OptionDescriptions',
              columns: ['Options_Available'],
              match_col: ['Free'],
              match_val: [1],
              match_case: ['WHERE']
            }
          }
        })
        .then((res) => {
          let result = JSON.parse(res.data.data);
          let initial_free_options = result.map(function(obj) {
            return obj['Options_Available'];
          });
          let temp_data = {
            Player_ID: [],
            Level: [],
            Day: [],
            Options_Available: [],
            Report_Cost: []
          }
          for (var i=0; i < initial_free_options.length; i++) {
            temp_data.Player_ID.push(this.player_id)
            temp_data.Level.push(0)
            temp_data.Day.push(0)
            temp_data.Options_Available.push(initial_free_options[i])
            temp_data.Report_Cost.push(0)
          }
          const path_send = 'send_to_db';
          axios.get(path_send, {
              params: {
                table: 'Table_Player_FreeReport',
                data: temp_data
              }
            })
            .then((res) => {
              res.status;
              this.get_options_and_descriptions()
              this.populate_times()
            })
            .catch((error) => {
              // eslint-disable-next-line
              console.error(error);
            });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    convertSeconds(hms) {
      var a = hms.split(':');
      return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
    },
    getMessages() {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_MessageBoxes',
              columns: ['Variable_name', 'Info/Button_description'],
              match_col: [
                'Variable_name', 'Variable_name', 'Variable_name', 'Variable_name', 'Variable_name',
                'Variable_name', 'Variable_name', 'Variable_name', 'Variable_name', 'Variable_name'],
              match_val: [
                'Button_ProceedStep5', 'Info_ConfiscateAll', 'Info_ConfiscteFinalBank', 'Info_CrimeNotIdentified', 'Info_DescriptionStep2',
                'Info_DescriptionStep3', 'Info_HonestJob', 'MsB_BuyReport', 'Info_DescriptionStep4Fam', 'Info_DescriptionStep5'],
              match_case: ['WHERE', 'OR', 'OR', 'OR', 'OR', 'OR', 'OR', 'OR', 'OR', 'OR']
            }
          }
        })
        .then((res) => {
          let result = JSON.parse(res.data.data);
          this.msg_proceed_step5 = result[0]['Info/Button_description']
          this.msg_confiscate_all = result[1]['Info/Button_description']
          this.msg_confiscate_final = result[2]['Info/Button_description']
          this.msg_notidentified = result[3]['Info/Button_description']
          this.msg_step2 = result[4]['Info/Button_description']
          this.msg_step3 = result[5]['Info/Button_description']
          this.msg_step4 = result[6]['Info/Button_description']
          this.msg_step5 = result[7]['Info/Button_description']
          this.msg_honest = result[8]['Info/Button_description']
          this.msg_buy_report = result[9]['Info/Button_description']
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    initial_timer() {
      if (this.existing_results !== null) {
        this.time_scenario = this.convertSeconds(this.existing_results['Time_Step1'])
      } else {
        this.time_scenario = 0
      }
      this.time_report = 0
      this.time_crime = 0
      this.time_launder = this.$all_variables['Var_TimeLaunder']
      this.time_results = 0
      clearInterval(this.timeoutTimer);
      this.start_timer(1)
    },
    fetch_existing() {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_Player_Results',
              columns: ['*'],
              match_col: ['Player_ID'],
              match_val: [this.player_id],
              match_case: ['WHERE']
            }
          }
        })
        .then((res) => {
          if (JSON.parse(res.data.data).length !== 0) {
            this.existing_results = JSON.parse(res.data.data)[0];
            if (this.existing_results !== null) {
              this.detect_completed()
            }
          } else {
            this.add_free_reports()
            this.initial_timer()
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    detect_completed() {
      if (this.$previous_route == 'Admin') {
        this.disable_dict = {
          1: true,
          2: true,
          3: true,
          4: true,
          5: false,
        }
      }
      if (this.existing_results['Step1_Complete'] !== null && !this.visibility[2]) {
        this.time_report = this.convertSeconds(this.existing_results['Time_Step2'])
        this.get_proceed_options()
        this.disable_collapse(1)
      }
      else if (this.existing_results['Step2_Complete'] !== null && !this.visibility[3]) {
        this.time_crime = this.convertSeconds(this.existing_results['Time_Step3'])
        this.disable_collapse(2)
      }
      else if (this.existing_results['Step3_Complete'] !== null && !this.visibility[4]) {
        this.time_launder = this.convertSeconds(this.existing_results['Time_Step4'])
        let result = this.existing_results
        let selected = null
        this.proceed_options.map( function(obj) {
          if (obj['name'] == result['Option_chosen'])
            selected = obj
        })
        this.proceed_selected_id = selected.id
        this.front_selected_id = result['Nr_Fronts_Chosen'] + 1
        this.jewel_selected_id = result['Jewellery_Chosen'] + 1
        this.next = !this.next
      }
      else if (this.existing_results['Step4_Complete'] !== null && !this.visibility[5]) {
        if (this.$previous_route == 'Admin') {
          this.disable_dict[4] = false
        }
        const path = 'fetch_from_db';
        axios.get(path, {
            params: {
              data: {
                table: 'Table_Player_Tx',
                columns: ['*', 'Tx_Nr'],
                match_col: ['Player_ID', 'Level', 'Day'],
                match_val: [this.player_id, 0, 0],
                match_case: ['WHERE', 'AND', 'AND']
              }
            }
          })
          .then((res) => {
            this.transactions = []
            let result = JSON.parse(res.data.data);
            if (result.length > 0) {
              let transact_from = {}
              let transact_to = {}
              for (let res of result) {
                this.transaction_options.map( function(obj) {
                  if (obj['name'] == res['From']) {
                    transact_from = obj
                    transact_from.to.map( function(obj2) {
                      if (obj2['name'] == res['To']) {
                        transact_to = obj2
                      }
                    })
                  }
                })
                this.change_from(transact_from)
                this.change_to(transact_to)
                this.trans_amount = res.Amount
                this.trans_theft_random = res['Theft_Risk_Random']
                this.trans_val_random = res['Valuation_Risk_Random']
                this.trans_conf_random = res['Confiscation_Risk_Random']
                this.add_transaction()

                if (this.transactions.length == result.length) {
                  this.save = true
                  if (this.existing_results['Anxiety'] !== null) {
                    this.time_results = this.convertSeconds(this.existing_results['Time_Step5'])
                    this.anxiety_completed = true
                    this.disable_collapse(4)
                  } else {
                    this.anxiety_completed = false
                    this.disable_collapse(4)
                  }
                }
              }
            }
            else if (this.existing_results['Anxiety'] !== null) {
              this.time_results = this.convertSeconds(this.existing_results['Time_Step5'])
              this.anxiety_completed = true
              this.disable_collapse(4)
            } else {
              this.anxiety_completed = false
              this.disable_collapse(4)
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
        });
      }
    }
  },
  activated() {
    if (this.visibility[5]) {
      setTimeout(() => {  window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight) }, 350);
      this.start_timer(5)
    }
  },
  created() {
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
    this.get_scenario_descript()
    this.getMessages()
    setTimeout(() => { this.refreshing = true }, 1000)
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id != 'None') {
        this.get_player_group()
      }
    },
    player_group: function() {
      if (this.player_group && this.player_group != 'None') {
        this.enabled = true
      } else {
        this.enabled = false
      }
    },
    trans_amount: function() {
      if (this.trans_amount !== null && this.trans_amount !== '')
        this.trans_amount = parseInt(this.trans_amount)
      this.check_max_amount()
    },
    free_option_selected: function() {        
      if (this.existing_results !== null && this.visibility[2] && !this.visibility[3]) {
        this.detect_completed()
      }
    },
    proceed_options: function() {
      if (this.existing_results !== null && this.visibility[3] && !this.visibility[4]) {
        this.detect_completed()
      }
    },
    illegal_option_chosen: function() {
      if (this.next) {
        this.disable_collapse(3)
      }
    },
    transaction_options: function() {
      if (this.existing_results !== null && this.visibility[4] && !this.visibility[5]) {
        this.detect_completed()
      }
    },
    existing_results: function() {
      if (this.existing_results !== null) {
        this.get_options_and_descriptions();
        this.initial_timer()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

tr , th , td  {
  vertical-align: middle;
}

.bordered > tr > td, 
.bordered > tr > th {
  border: 2px solid black;
}

.border-div {
  border: 1px solid black;
  display: inline-block;
  width: 70%;
}

.options-dropdown {
  border: 1px solid black;
  border-top: 0px;
  width: 100%;
}

#options-free :hover,
#options-pay :hover,
#options-from :hover,
#options-to :hover {
  background-color: lightgray;
}

.selected-option {
  width: 30%;
  background-color: lightgray;
}

.selected-option :hover {
  background-color: white;
}

.selected-option :hover {
  background-color: white;
}

.modal_text {
  padding: 10px;
}

.finances_table_right {
  text-align: right;
  margin: 2px;
}

.table_border_style {
  border-top: 2px solid black; 
  border-bottom: 2px solid black;
  border: none;
}

.input_amount {
  background: transparent;
  border-color: transparent;
  border-image-outset: 0;
  width: 100%;
  outline: none;
}

.transaction_table {
  width: 100%;
}

.transaction_table th {
  width: 15%;
}

.fix-width-table {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.fix-width-table div {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

b-button {
  width: 100%;
}

.success {
  color: rgb(0, 150, 0) !important;
  border: 3px solid rgb(1, 150, 1) !important;
  border-radius: 10px;
}

.failure {
  color: rgb(200, 1, 1) !important;
  border: 3px solid rgb(200, 1, 1) !important;
  border-radius: 10px;
}

.notSelected {
  color: rgba(0, 0, 0, 0.5);
}

</style>
