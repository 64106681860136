<template>
  <b-container fluid>
    <vue-modal-2
      @on-close="$vm2.close('msg_alias')"
      style="z-index: 10"
      name="msg_alias"
      modalSize="lg"
      noHeader
      noFooter
    >
    <div style="border: 5px solid rgba(0, 0, 0, 0.7)">
      <div class="centered" style="padding: 10px">
        <h4 style="color:blue"><u>Unique Alias: {{this.new_alias}}</u></h4>
        <p style="text-align: left">
                <strong>Remembering your alias name is very important</strong> because if you disconnect or want to continue 
                where you left of, you'll have to remember/re-enter your alias name.<br><br>
                We have added three random digits to your chosen alias name to ensure uniqueness.<br>
                Please re-enter your unique alias below and write it down on a paper, a txt file or make a screenshot. <br> Then press enter to continue.
        </p>
        <p style="color:grey; font-size: 60%; margin: 0px">{{ msg }}</p>
        <input style="margin-top: 5px" type="text" v-model="alias_confirm" placeholder="New alias" @keydown.enter="proceed">
        <br>
      </div>
    </div>
    </vue-modal-2>
    <vue-modal-2
      @on-close="$vm2.close('msg_age')"
      style="z-index: 10"
      name="msg_age"
      modalSize="lg"
      noHeader
      noFooter
    >
    <div style="border: 5px solid rgba(0, 0, 0, 0.7)">
      <div class="centered" style="padding: 10px">
        <p v-html="msg_underaged"></p>
      </div>
    </div>
    </vue-modal-2>

    <b-row>
      <b-col>
        <div class="message_box">
          <idxbox pos = 2 />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="message_box">
          <h4 style="color: blue">DEMOGRAPHIC INFORMATION:</h4>
          <b-row><p>Please enter the following information:</p></b-row>
          <b-row>
            <b-col cols="3"><label for="alias"><strong>Alias name:</strong></label></b-col>
            <b-col><input id="alias" class="input_boxes" v-model="diction.alias"></b-col>
            <b-tooltip target="alias" placement="right">Please enter a name (not your real name).</b-tooltip>
          </b-row>
          <b-row>
            <b-col cols="3"><label for="attempts"><strong>Attempts:</strong></label></b-col>
            <b-col><input id="attempts" type="Number" min="1" class="input_boxes" v-model.number="diction.attempts" @keypress="isNumber($event)"></b-col>
            <b-tooltip target="attempts" placement="right">Number of times you've attempted this research project (even when using a new alias name). This is really important!</b-tooltip>
          </b-row>
          <b-row>
            <b-col cols="3"><label for="age"><strong>Age:</strong></label></b-col>
            <b-col><input id="age" type="Number" min="1" class="input_boxes" v-model.number="diction.age" @keypress="isNumber($event)"></b-col>
          </b-row>
          <br>

          <b-row><p>Select the most appropriate descriptions from the dropdown boxes below for the required demographic information:</p></b-row>
          <b-row style="margin-bottom: 2px">
            <b-col cols="3"><label for="gender"><strong>Gender:</strong></label></b-col>
            <b-col><Dropdown
              :options="[
                { id: 1, name: 'Male'}, 
                { id: 2, name: 'Female'}, 
                { id: 3, name: 'Prefer not to say'}]"
              placeholder="Select Gender"
              v-on:selected="gender_option"
              class="input_boxes"
              id='gender'>
            </Dropdown></b-col>
          </b-row>
          
          <b-row style="margin-bottom: 2px">
            <b-col cols="3"><label for="country"><strong>Country of residence:</strong></label></b-col>
            <b-col><Dropdown
              :options="countries"
              :maxItem="9999"
              placeholder="Select Country"
              v-on:selected="country_option"
              class="input_boxes"
              id="country">
            </Dropdown></b-col>
          </b-row>

          <b-row style="margin-bottom: 2px">
            <b-col cols="3"><label for="dropdown_skills"><strong>Strategising skills:</strong></label></b-col>
            <b-col><Dropdown
              :options="[
                { id: 1, name: 'No skills'},
                { id: 2, name: 'Poor skills'},
                { id: 3, name: 'Average skills'},
                { id: 4, name: 'Good skills'},
                { id: 5, name: 'Excellent skills'}]"
              placeholder="Rate Strategising Skills"
              v-on:selected="skills_option"
              class="input_boxes"
              id="dropdown_skills">
            </Dropdown></b-col>
            <b-tooltip target="dropdown_skills" placement="right">(Rate your general strategising skills when playing strategy games.)</b-tooltip>
          </b-row>

          <b-row style="margin-bottom: 2px">
            <b-col cols="3"><label for="dropdown_knowledge"><strong>Money laundering knowledge:</strong></label></b-col>
            <b-col><Dropdown
              :options="[
                { id: 1, name: 'No knowledge'},
                { id: 2, name: 'Poor knowledge'},
                { id: 3, name: 'Average knowledge'},
                { id: 4, name: 'Good knowledge'},
                { id: 5, name: 'Excellent knowledge'}]"
              placeholder="Rate ML Knowledge"
              v-on:selected="knowledge_option"
              class="input_boxes"
              id="dropdown_knowledge">
            </Dropdown></b-col>
            <b-tooltip target="dropdown_knowledge" placement="right">
              (Rate your general knowledge of money laundering considering in combination the various mechanisms that can be used,
              the numerous laundering techniques available for these mechanisms, as well as specific 'Know-Your-Client' or other anti-money 
              laundering regulations and laws in your country.)
            </b-tooltip>
          </b-row>

          <b-row style="margin-bottom: 2px">
            <b-col cols="3"><label for="gross_income"><strong>Gross monthly income:</strong></label></b-col>
            <b-col><Dropdown
              :options="[
                { id: 1, name: '0 - 3\'000'},
                { id: 2, name: '3\'000 - 5\'000'},
                { id: 3, name: '5\'000 - 10\'000'},
                { id: 4, name: '10\'000 - 20\'000'},
                { id: 5, name: '20\'000 - 30\'000'},
                { id: 6, name: '30\'000 - 50\'000'},
                { id: 7, name: '50\'000 - 80\'000'},
                { id: 8, name: 'More than 80\'000'},
                { id: 9, name: 'Prefer not to say'}]"
              :maxItem="9"
              placeholder="Select Income Category"
              v-on:selected="income_option"
              class="input_boxes"
              id="gross_income">
            </Dropdown></b-col>
            <b-tooltip target="gross_income" placement="right">
              (Choose the category depending on your own income currency and before taxation or other deductions 
              are taken into account.)
            </b-tooltip>
          </b-row>
              <div id='id_proceed' style="width: 140px;" :disabled="!checkFilled(diction)">
                <b-button class="proceed" :disabled="checkFilled(diction) || !enabled" v-on:click="open_modal">Proceed</b-button>
              </div>
              <b-tooltip target="id_proceed" placement="right">You have to complete all the fields before you can proceed.</b-tooltip>
        </div>
      </b-col>
    </b-row>
    <exitpanel/>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js';
import idxbox from "@/components/index_box.vue";
import exitpanel from "@/components/exit_panel.vue";
import Dropdown from "@/components/dropdown.vue";
import axios from 'axios'

export default {
  name: "Participant",
  mixins: [common],
  components: {
    exitpanel,
    idxbox,
    Dropdown
  },
  data() {
    return {
      diction: {
        alias: "",
        attempts: null,
        age: null,
        gender: null,
        country: null,
        skill: null,
        knowledge: null,
        income: null
      },
      alias_confirm: '',
      new_alias: '',
      msg: '',
      msg_underaged: '',
      suffix: '',
      countries: [],
      player_id: null,
      enabled: false
    }
  },
  methods: {
    gender_option(v) {
      this.diction.gender = v.name
    },
    country_option(v) {
      this.diction.country = v.name
    },
    skills_option(v) {
      this.diction.skill = v.id
    },
    knowledge_option(v) {
      this.diction.knowledge = v.id
    },
    income_option(v) {
      this.diction.income = v.id
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkFilled(obj) {
      for (var key in obj) {
        if (obj[key] === null || obj[key] == "" || obj[key] === undefined)
          return true;
      }
      return false;
    },
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    open_modal() {
      if (this.diction.age >= 18) {
        this.new_alias = this.diction.alias + '-' + this.suffix
        const path = 'fetch_column_from_db';
        axios.get(path, {
            params: {
              data: {
                table: 'Table_Participants',
                columns: ['Alias']
              }
            }
          })
          .then((res) => {
            let list_aliases = JSON.parse(res.data.data).map(function(obj) {
                return obj['Alias'];
              });
            if (!list_aliases.includes(this.new_alias)){
              this.$vm2.open('msg_alias')
            } else {
              this.generate_suffix()
              this.open_modal()
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
        });
      } else {
        this.$vm2.open('msg_age')
      }
    },
    proceed() {
          if (this.alias_confirm == this.new_alias) {
            Vue.prototype.$alias = this.new_alias

            const path_participants = 'send_to_db';
            axios.get(path_participants, {
                params: {
                  table: 'Table_Participants',
                  data: {
                    Player_ID: [this.player_id],
                    Alias: [this.new_alias],
                    Attempts: [this.diction.attempts],
                    Age: [this.diction.age],
                    Gender: [this.diction.gender],
                    Country: [this.diction.country],
                    Strat_Skills: [this.diction.skill],
                    ML_Knowledge: [this.diction.knowledge],
                    Income: [this.diction.income]
                  }
                }
              })
              .then((res) => {
                res.status;
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.error(error);
              });
            
            const path_consent = 'send_to_db';
            axios.get(path_consent, {
                params: {
                  table: 'Table_Time',
                  data: {
                    Player_ID: [this.player_id],
                    Demographics: [this.getTime()]
                  }
                }
              })
              .then((res) => {
                res.status;
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.error(error);
              });
            this.$router.push("/charity")
          } else {
            this.msg = 'The alias you re-entered does not match your chosen alias (remember it is case sensistive). Please try again!'
          }
    },
    getCountries () {
      const path = 'get_countries';
      axios.get(path, {
          params: {
            variable: ''
          }
        })
        .then((res) => {
          this.countries = res.data.variable;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    generate_suffix() {
      this.suffix = Math.floor(Math.random() * Math.floor(999)) + 1;
      this.suffix = this.suffix.toString();
    },
    getMessages() {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_MessageBoxes',
              columns: ['Info/Button_description'],
              match_col: ['Variable_name'],
              match_val: ['Info_underage'],
              match_case: ['WHERE']
            }
          }
        })
        .then((res) => {
          this.msg_underaged = JSON.parse(res.data.data)[0]['Info/Button_description'];
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    }
  },
  created() {
    this.getCountries();
    this.getMessages();
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
    this.generate_suffix()
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id != 'None') {
        this.enabled = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

.input_boxes {
  width: 30%
}

</style>
