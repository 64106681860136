<template>
  <b-container fluid >
    <div class="centered">
      <b-row>
        <div class="message_box">
          <h1 :key="$alias">Welcome to the Money Laundering Simulation Game {{$alias}}</h1> 
          <h5>
            <br>Thank you for your willingness to assist with research!<br><br>
          </h5>
          <h5>
            The purpose of the simulation game:
          </h5>
          <p>
            The purpose is to learn how money launderers might react to possible specific anti-money laundering regulations if implemented in practice, 
            as well as their adaptations (if any) when confronted with risk and uncertainty. The ultimate aim of the research is to contribute 
            towards decreasing money laundering and crime.
          </p>
          <h5>
            Need for strategic gamers:
          </h5>
          <p>
            Both online strategic gamers and strategic board gamers are uniquely situated due to their strategic thinking and problem solving 
            abilities within a restricted time limit. It is, therefore, possible to provide strategic gamers with an objective and the rules of the game,
            knowing you will be able to devise an appropriate strategy to achieve the given objective.
          </p>
        </div>
        <div class="message_box">
          <b-row>
            <h6 style="margin-bottom: 0px;">Please enter the password to participate in the Money Laundering Simulation Game:</h6>
            <p style="margin: 0px; color: grey">(or enter your alias to continue where you left off)</p>
          </b-row>

          <b-row>
            <b-col>
              <div style="margin-top: 15px">
                <v-icon class="icon-eye" scale=2 @click="showPassword()" v-if="visibility == 'password'" name="eye-slash"/>
                <v-icon class="icon-eye" scale=2 @click="hidePassword()" v-if="visibility == 'text'" name="eye"/>
                <input id="passw" :type="visibility" v-model="password" @keydown.enter="checkPassword" placeholder="Password" ref="passw">
              </div>
            </b-col>
          </b-row>

          <b-row>
            <p/>
            <div v-show="failed" style="border: 2px solid grey">
              <br>
              <p v-html="replace_vars_in_text(msg)" style="text-align: left; color: grey;"></p>
            </div>
          </b-row>
        </div>
      </b-row>
    </div>
    <b-button class="centered2" v-on:click="hasHistory">Abandon study</b-button>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js';
import Icon from 'vue-awesome/components/Icon'
import axios from 'axios';

export default {
  name: 'SignIn',
  mixins: [common],
  components: {
    'v-icon': Icon
    },
  data () {
    return {
      password: "",
      failed: false,
      msg: null,
      player_id: undefined,
      visibility: 'password'
    }
  },
  methods: {
    checkPassword() {
      console.log('entered:', this.password, this.$all_variables['Var_Password'])
      if (this.password == this.$all_variables['Var_Password']) {
        this.createNewId()
        this.failed = false
      } else if (this.password == this.$all_variables['Var_Admin']){
        this.$router.push("/admin")
        this.failed = false
      } else {
        Vue.prototype.$alias = this.password
        if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
        console.log('player', this.player_id)
      }
    },
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    hasHistory () {
      if (window.history.length > 2) {
        this.$router.go(-1) 
      } else {
        window.location.href = "https://www.google.com"
      }
    },
    getPasswords () {
      console.log(this.$all_variables['Var_Password'])
    },
    getDates () {
      this.start_date = this.$all_variables['Var_StartDate']
      this.end_date = this.$all_variables['Var_EndDate']
    },
    getPasswordInfo () {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_MessageBoxes',
              columns: ['Info/Button_description'],
              match_col: ['Variable_name'],
              match_val: ['Info_password'],
              match_case: ['WHERE']
            }
          }
        })
        .then((res) => {
          this.msg = JSON.parse(res.data.data)[0]['Info/Button_description'];
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    createNewId() {
      fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
        const path = 'create_new_id';
        axios.get(path, {
            params: {
              ip: ip,
              time: this.getTime()
            }
          })
          .then((res) => {
            this.player_id = res.data.player_id;
            console.log(this.player_id)
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
        });
      });
    },
    focusInput() {
      this.$refs.passw.focus();
    },
    hidePassword() {
      this.visibility = 'password'
    },
    showPassword() {
        this.visibility = 'text';
    }
  },
  created () {
    this.getPasswordInfo();
    this.getPasswords()
  },
  mounted() {
    this.focusInput();
  },
  watch: {
    fetched: function() {
      this.getPasswords()
    },
    player_id: function() {
      if (!isNaN(this.player_id) && this.player_id !== null && this.player_id !== 'None' && this.player_id !== undefined && this.$alias !== '') {
        this.navigate_save(this.player_id)
        this.failed = false
      } else if (this.player_id !== ''){
        this.failed = true
        this.password = ""
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

@media screen and (max-width: 960px) {
  .row {
    display: inline-block;
  }
}


#passw {
  width: 50%;
  border-radius: 2px;
  top: 10%;
}

.centered2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: fixed;
  bottom: 5px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-eye {
  position: absolute;
  padding-left: 10px;
  left: 70%;
  z-index: 2;
}
</style>