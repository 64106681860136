<template>
  <div>
    <vue-modal-2
      style="z-index: 10"
      name="enter_alias"
      modalSize="md"
      noHeader
      noFooter
    >
    <div style="border: 5px solid rgba(0, 0, 0, 0.7)">
      <div class="centered" style="padding: 10px">
        <h4 style="color:blue"><u>Unique Alias</u></h4>
        <p>{{ msg }}</p>
        <input placeholder="Alias" v-model="entered" @keypress.enter="set_alias()" ref="alias_input"/>
      </div>
    </div>
    </vue-modal-2>
  </div>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js';

export default {
  name: "AliasModal",
  mixins: [common],
  data() {
      return {
          entered: '',
          player_id: undefined,
          msg: 'You have either refreshed or tried forceably navigate... , please enter your alias name to confirm it\'s you.'
      }
  },
  methods: {
      set_alias() {
        Vue.prototype.$alias = this.entered;
        this.$vm2.close('enter_alias')
        if (this.$player_id == undefined) {
          Vue.prototype.$player_id = this.player_id = this.get_player_id()
        } else {
          this.player_id = this.$player_id
        }
      }
  },
  mounted() {
    this.$vm2.open('enter_alias');
    setTimeout(() => { this.$refs.alias_input.focus(); }, 350);
  },
  watch: {
    player_id: function() {
      if (!isNaN(this.player_id) && this.player_id !== null && this.player_id !== 'None' && this.player_id !== undefined && this.$alias !== '') {
        this.navigate_save(this.player_id)
      } else if (this.player_id !== ''){
        Vue.prototype.$toSave = true
        this.$router.push("/")
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

</style>