<template>
  <b-container>
    <b-row  style="padding: 10px">
      <div class="message_box">
        <b-row>
          <h3 style="color: blue">GAME 2: Anxiety</h3>
          <h4>The bomb is about to go off randomly in any one of the parcels…</h4>
          <p style="color: blue">How anxious are you feeling now compared to the previous game (when you knew how many parcels there were)?</p>
          <label for="anxiety">Select the most appropriate description from the adjacent dropdown box:</label>
          <p></p>
          <Dropdown
            :options="[
              {'id': 1, 'name': 'Much better'},
              {'id': 2, 'name': 'Better'},
              {'id': 3, 'name': 'Somewhat better'},
              {'id': 4, 'name': 'About the same'},
              {'id': 5, 'name': 'Somewhat worse'},
              {'id': 6, 'name': 'Worse'},
              {'id': 7, 'name': 'Much worse'}]"
            :maxItem="7"
            placeholder="Select anxiety comparison"
            v-on:selected="anxiety_option"
            id="anxienty">
          </Dropdown>
        </b-row>
        <b-row>
          <div id='id_proceed' style="width: 10%; min-width: 140px" :disabled="checkFilled(anxiety_level)">
            <b-button class="proceed" :disabled="!checkFilled(anxiety_level) || !enabled" v-on:click="proceed">Proceed</b-button>
          </div>
          <b-tooltip target="id_proceed" placement="right">Please select an anxiety level.</b-tooltip>
        </b-row>
        <b-row>
          <exitpanel/>
        </b-row>
      </div>
    </b-row>
  <p style="color:blue; position:fixed; right:3%; top:3%;">Alias: {{this.$alias}}</p>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import exitpanel from "@/components/exit_panel.vue";
import Dropdown from "@/components/dropdown.vue";
import axios from 'axios';

export default {
  name: "Game1Anxiety",
  mixins: [common],
  components: {
    exitpanel,
    Dropdown
  },
  data() {
    return {
      anxiety_level: null,
      player_id: null,
      enabled: false
    }
  },
  methods: {
    anxiety_option(v) {
      if ('id' in v)
        this.anxiety_level = v.id;
    },
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    proceed(){
      const path_consent = 'send_to_db';
      axios.get(path_consent, {
          params: {
            table: 'Table_Games',
            data: {
              Player_ID: [this.player_id],
              Anxiety_Game2: [this.anxiety_level-4]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      axios.get(path_consent, {
          params: {
            table: 'Table_Time',
            data: {
              Player_ID: [this.player_id],
              Game2Anxiety: [this.getTime()]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      this.$router.push("/game2results")
    },
    checkFilled(val) {
      return (val !== null && !isNaN(val))
    }
  },
  created() {
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id != 'None') {
        this.enabled = true
      }
    },
    anxiety_level: function() {
      console.log(this.anxiety_level - 4)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

.message_box {
  margin: 50px;
  padding: 50px;
  max-width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.proceed {
  width: 10%;
  margin-left: 0px;
  margin-bottom: 0px;
}

</style>
