<template>
  <div class="pdf_view">
    <PDFJSViewer/>
  </div>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import PDFJSViewer from '@/components/PDFJSViewer'

export default {
  name: "Ethics",
  mixins: [common],
  components: {
    PDFJSViewer
  },
  created() {
    if (this.player_id && this.player_id !== 'None') {
      Vue.prototype.$player_id = this.player_id
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

</style>
