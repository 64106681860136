var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',{staticStyle:{"padding":"10px"}},[_c('div',{staticClass:"message_box"},[_c('b-row',[_c('h3',{staticStyle:{"color":"blue"}},[_vm._v("GAME 2: Anxiety")]),_c('h4',[_vm._v("The bomb is about to go off randomly in any one of the parcels…")]),_c('p',{staticStyle:{"color":"blue"}},[_vm._v("How anxious are you feeling now compared to the previous game (when you knew how many parcels there were)?")]),_c('label',{attrs:{"for":"anxiety"}},[_vm._v("Select the most appropriate description from the adjacent dropdown box:")]),_c('p'),_c('Dropdown',{attrs:{"options":[
            {'id': 1, 'name': 'Much better'},
            {'id': 2, 'name': 'Better'},
            {'id': 3, 'name': 'Somewhat better'},
            {'id': 4, 'name': 'About the same'},
            {'id': 5, 'name': 'Somewhat worse'},
            {'id': 6, 'name': 'Worse'},
            {'id': 7, 'name': 'Much worse'}],"maxItem":7,"placeholder":"Select anxiety comparison","id":"anxienty"},on:{"selected":_vm.anxiety_option}})],1),_c('b-row',[_c('div',{staticStyle:{"width":"10%","min-width":"140px"},attrs:{"id":"id_proceed","disabled":_vm.checkFilled(_vm.anxiety_level)}},[_c('b-button',{staticClass:"proceed",attrs:{"disabled":!_vm.checkFilled(_vm.anxiety_level) || !_vm.enabled},on:{"click":_vm.proceed}},[_vm._v("Proceed")])],1),_c('b-tooltip',{attrs:{"target":"id_proceed","placement":"right"}},[_vm._v("Please select an anxiety level.")])],1),_c('b-row',[_c('exitpanel')],1)],1)]),_c('p',{staticStyle:{"color":"blue","position":"fixed","right":"3%","top":"3%"}},[_vm._v("Alias: "+_vm._s(this.$alias))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }