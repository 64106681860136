<template>
  <b-container :key="`${$all_variables['Var_Game1Time']}`">
    <b-row>
      <b-col cols = "6"><h3 style="color: blue;">GAME 2: Play for Real</h3></b-col>
      <b-col cols = "3"><h3 style="allign-text: right;">Time remaining: </h3></b-col>
      <b-col cols = "3"><h3 style="color: red;">{{ convertMS(timeleft) }}</h3></b-col>
    </b-row>
    <b-row>
      <div class="message_box">
        <b-row><p>
          <strong>This round is for real.</strong> You have only <span style="color: blue;">15 seconds</span>  
          to move the slider until you are satisfied with the number of parcels you want to deliver.
        </p></b-row>
        <b-row>
          <br>
          <warehouse :slider_value="slider_value" :game2="true"/>
        </b-row>
        <b-row>
            <b-col>
                <p>Remaining parcels: ???</p>
                <vue-slider :disabled="timeleft <= 0" v-model="slider_value" />
                <p class="small-text">(Click, hold and drag the slider)</p>
            </b-col>
            <b-col>
                <b-row>
                    <b-col cols="4"></b-col>
                    <b-col>
                        <b-row rows="1">
                            <b-col><p>Parcels to be delivered (<span style="color: blue;">IF available</span>): </p></b-col>
                            <b-col><p>{{ slider_value }}</p></b-col>
                        </b-row>
                        <b-row rows="1">
                            <b-col><p>Delivery fee per parcel: </p></b-col>
                            <b-col><p>{{ financial($all_variables['Var_DeliveryFee']) }}</p></b-col>
                        </b-row>
                        <b-row rows="1">
                            <b-col><p><span style="color: blue;">POTENTIAL</span> earnings: </p></b-col>
                            <b-col><p class="top-bot-border">{{ financial(slider_value * $all_variables['Var_DeliveryFee']) }}</p></b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
      </div>
    </b-row>
    <div id='id_proceed' style="width: 140px;" :disabled="wasMoved">
      <b-button  class="proceed" v-on:click="proceed" :disabled="!enabled || (timeleft > 0 && !wasMoved)">See results</b-button>
    </div>
    <b-tooltip target="id_proceed" placement="right">You have to move the slider before you can proceed.</b-tooltip>
    <exitpanel/>
  <p style="color:blue; position:fixed; right:3%; top:3%;">Alias: {{this.$alias}}</p>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import exitpanel from "@/components/exit_panel.vue";
import warehouse from "@/components/warehouse.vue";
import axios from 'axios';

export default {
  name: "Game2Play",
  mixins: [common],
  components: {
    exitpanel,
    warehouse,
    VueSlider
  },
  data() {
    return {
      timeoutTimer: null,
      slider_value: 0,
      timeleft: 0,
      player_id: null,
      enabled: false,
      wasMoved: false
    }
  },
  methods: {
    start_timer() {
      this.timeleft = this.$all_variables['Var_Game2Time']
      this.timeoutTimer = setInterval(this.set_timeout, 1000);
    },
    set_timeout() {
      if(this.timeleft <= 0){
        clearInterval(this.timeoutTimer);
        this.timeleft += 1
      }
      this.timeleft -= 1;
    },
    financial(x) {
        return Number.parseFloat(x).toFixed(2);
    },
    convertMS(value) {
      const sec = parseInt(value, 10); // convert value to number if it's string
      let minutes = Math.floor((sec) / 60); // get minutes
      let seconds = sec - (minutes * 60); //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}
      return minutes+':'+seconds; // Return is HH : MM : SS
    },
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    proceed () {
      clearInterval(this.timeoutTimer);

      if (!this.wasMoved) {
        this.slider_value = null
      }
      const path = 'send_to_db';
      axios.get(path, {
          params: {
            table: 'Table_Games',
            data: {
              Player_ID: [this.player_id],
              TimeLeft_Game2: ['00:'+this.convertMS(this.timeleft)],
              Slider_Game2: [this.slider_value]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      axios.get(path, {
          params: {
            table: 'Table_Participants',
            data: {
              Player_ID: [this.player_id],
              Uncertainty_Appetite: [this.slider_value]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      axios.get(path, {
          params: {
            table: 'Table_Time',
            data: {
              Player_ID: [this.player_id],
              Game2Play: [this.getTime()]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      this.$router.push("/game2anxiety");
    }
  },
  created() {
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id != 'None') {
        this.start_timer()
        this.enabled = true
      }
    },
    slider_value: function() {
      this.wasMoved = true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

</style>
