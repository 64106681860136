<template>
  <b-container>
    <b-row>
      <div class="message_box">
        <idxbox pos = 6 />
      </div>
    </b-row>
    <b-row>
      <div class="message_box">
        <b-row>
          <b-col cols="10">
            <p v-html="replace_vars_in_text(info1)"></p>
          </b-col>
          <b-col cols="2">
            <b-row>
              <img alt="Diagram_1" src="../../assets/clock.png"/>
            </b-row>
          </b-col>
        </b-row>
        <p><br></p>
        <b-row>
          <b-col cols="10">
            <p v-html="replace_vars_in_text(info2)"></p>
          </b-col>
          <b-col cols="2">
            <b-row>
              <img alt="Diagram_1" src="../../assets/knowledge.png" class="centered" />
            </b-row>
          </b-col>
        </b-row>
        <p><br></p>
        <b-row>
          <b-col cols="10">
            <p v-html="replace_vars_in_text(info3)"></p>
          </b-col>
          <b-col cols="2">
            <b-row>
              <img alt="Diagram_1" src="../../assets/interconnected.png" class="centered" />
            </b-row>
          </b-col>
        </b-row>
        <p><br></p>
        <b-row>
          <img alt="Diagram_1" src="../../assets/diagrams/MLStructure.png" class="centered" />
        </b-row>
      </div>
    </b-row>
    <b-row>
      <div class="message_box">
        <b-row>
          <div style="border-bottom: 1px solid black">
            <p v-html="replace_vars_in_text(info4)"></p>
            <br>
          </div>
        </b-row>
        <p><br></p>
        <b-row>
          <div style="border-bottom: 1px solid black">
            <p v-html="replace_vars_in_text(info5)"></p>
            <br>
          </div>
        </b-row>
        <p><br></p>
        <b-row>
          <div style="border-bottom: 1px solid black">
            <p v-html="replace_vars_in_text(info6)"></p>
            <br>
          </div>
        </b-row>
        <p><br></p>
        <b-row>
          <div style="border-bottom: 1px solid black">
            <p v-html="replace_vars_in_text(info7)"></p>
            <br>
          </div>
        </b-row>
        <p><br></p>
        <b-row>
          <div style="border-bottom: 1px solid black">
            <p v-html="replace_vars_in_text(info8)"></p>
            <br>
          </div>
        </b-row>
        <p><br></p>
        <b-row>
          <p v-html="replace_vars_in_text(info9)"></p>
        </b-row>
      </div>
    </b-row>
    <div id='id_proceed' style="width: 10%; min-width: 140px" :disabled="!timeleft > 0 || !enabled">
      <b-button :disabled="timeleft > 0 || !enabled" class="proceed" v-on:click="proceed">Proceed</b-button>
    </div>
    <b-tooltip target="id_proceed" placement="right">Although it is a lot to read, this will help you to understand the structure of the simulation game. <br> Button will be enabled shortly</b-tooltip>
    <exitpanel :screennum="2"/>
  <p style="color:blue; position:fixed; right:3%; top:3%;">Alias: {{this.$alias}}</p>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import idxbox from "@/components/index_box.vue";
import exitpanel from "@/components/exit_panel.vue";
import axios from 'axios';

export default {
  name: "MLPracIntro",
  mixins: [common],
  components: {
    idxbox,
    exitpanel
  },
  data() {
    return {
      timeleft: 0,
      info1: '',
      info2: '',
      info3: '',
      info4: '',
      info5: '',
      info6: '',
      info7: '',
      info8: '',
      info9: '',
      player_id: null,
      enabled: false
    }
  },
  methods: {
    start_timer() {
      this.timeleft = this.$all_variables['Var_MLGameIntroTime']
      this.timeoutTimer = setInterval(this.set_timeout, 1000);
    },
    set_timeout() {
      if(this.timeleft <= 0){
        clearInterval(this.timeoutTimer);
        this.timeleft += 1
      }
      this.timeleft -= 1;
    },
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    proceed () {
      const path = 'send_to_db';
      axios.get(path, {
          params: {
            table: 'Table_Time',
            data: {
              Player_ID: [this.player_id],
              MLPracIntro: [this.getTime()]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      this.$router.push("/mlpracticelevel")
    },
    getVariables() {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_MessageBoxes',
              columns: ['Variable_name', 'Info/Button_description'],
              match_col: [
                'Variable_name', 'Variable_name', 'Variable_name', 'Variable_name', 'Variable_name',
                'Variable_name', 'Variable_name', 'Variable_name', 'Variable_name'],
              match_val: [
                'Info_MLGameAnxiety', 'Info_MLIntro1', 'Info_MLIntro2', 'Info_MLIntro3', 
                'Info_MLGameStep1', 'Info_MLGameStep2', 'Info_MLGameStep3', 'Info_MLGameStep4', 'Info_MLGameStep5'],
              match_case: ['WHERE', 'OR', 'OR', 'OR', 'OR', 'OR', 'OR', 'OR', 'OR']
            }
          }
        })
        .then((res) => {
          let result = JSON.parse(res.data.data)
          this.info8 = result[0]['Info/Button_description'];
          this.info4 = result[1]['Info/Button_description'];
          this.info5 = result[2]['Info/Button_description'];
          this.info6 = result[3]['Info/Button_description'];
          this.info7 = result[4]['Info/Button_description'];
          this.info9 = result[5]['Info/Button_description'];
          this.info1 = result[6]['Info/Button_description'];
          this.info2 = result[7]['Info/Button_description'];
          this.info3 = result[8]['Info/Button_description'];
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    }
  },
  created() {
    this.getVariables()
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id != 'None') {
        this.start_timer()
        this.enabled = true
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

p {
  margin: 0px;
}

</style>
