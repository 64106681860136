<template>
  <b-container fluid @wheel="handleWheel()">
    <b-row>
      <b-col><div class="message_box"><idxbox pos = 3 /></div></b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="message_box">
          <b-row>
            <h4 style="color: blue">GENERAL APPETITE FOR RISK AND UNCERTAINTY:</h4>
            <div style="border-bottom: 1px solid black;"><br></div>
            <p><br>
              In order to determine your general appetite for risk and uncertainty you are required to answer some questions 
              and then participate in 2 brief games. Both of these games (and the money laundering simulation game) will provide 
              you with an opportunity to accumulate some 'winnings' for a charity of your choice. 
              <br><br>
              Select a charity from the list below and, as you progress by completing the various tasks and levels of the 
              simulation game, your accumulated 'winnings' on behalf of this charity will be entered into a random draw. 
              Such accumulated winnings from 50 participants obtained in the random draw will then be totalled per charity 
              and paid over upon the conclusion of the research fieldwork.
            </p>
            <div style="border-top: 1px solid black;"><br></div>
          </b-row>
          <b-row>
          </b-row>
          <b-row style="margin-bottom: 2px">
            <p>Choose the charity from the dropdown box below whom is closest to your heart:</p>
            <b-col cols="5"><label for="charity"><strong>Charity:</strong></label></b-col>
            <b-col><Dropdown
              :options="[
                { 'id': 1, 'name': 'Childhood Cancer Foundation: CHOC' },
                { 'id': 2, 'name': 'Endangered Wildlife Trust' },
                { 'id': 3, 'name': 'Gift of the Givers' },
                { 'id': 4, 'name': 'Girls and Boys Town' },
                { 'id': 5, 'name': 'Human Trafficing Foundation' },
                { 'id': 6, 'name': 'Meals on Wheels' },
                { 'id': 7, 'name': 'SA Guide Dog Association' },
                { 'id': 8, 'name': 'Save the Rhino' },
                { 'id': 9, 'name': 'SPCA: Society for the Prevention of Cruelty to Animals' },
                { 'id': 10, 'name': 'Vulpro (Vulture conservation)' },
                { 'id': 11, 'name': 'Wetnose' }]"
              :maxItem="12"
              placeholder="Select Charity"
              v-on:selected="charity_option"
              class="input_boxes"
              id="charity">
            </Dropdown></b-col>
          </b-row>
          <b-row style="margin-bottom: 2px">
            <p>Select the most appropriate description from the dropdown box below in terms of your appetite for risk in general:</p>
            <b-col cols="5"><label for="risk"><strong>How do you see yourself in terms of risk:</strong></label></b-col>
            <b-col><Dropdown
              :options="[
                {'id': 1, 'name': '0 - Not at all willing to take risks'},
                {'id': 2, 'name': '1'},
                {'id': 3, 'name': '2'},
                {'id': 4, 'name': '3'},
                {'id': 5, 'name': '4'},
                {'id': 6, 'name': '5'},
                {'id': 7, 'name': '6'},
                {'id': 8, 'name': '7'},
                {'id': 9, 'name': '8'},
                {'id': 10, 'name': '9'},
                {'id': 11, 'name': '10 - Very willing to take risks'}]"
              :maxItem="11"
              placeholder="Select Risk Appetite"
              v-on:selected="risk_option"
              class="input_boxes"
              id="risk">
            </Dropdown></b-col>
            <b-tooltip target="risk" placement="right">
              Are you generally a person who is fully prepared to take risks or do you try to avoid taking risks?
            </b-tooltip>
          </b-row>
          <b-row style="margin-bottom: 2px">
            <p>Select the most appropriate description from the dropdown box below in terms of your appetite for uncertainty in general:</p>
            <b-col cols="5"><label for="uncertainty"><strong>How do you see yourself in terms of uncertainty:</strong></label></b-col>
            <b-col><Dropdown
              :options="[
                {'id': 1, 'name': '0 - Avoid uncertainty at all costs'},
                {'id': 2, 'name': '1'},
                {'id': 3, 'name': '2'},
                {'id': 4, 'name': '3'},
                {'id': 5, 'name': '4'},
                {'id': 6, 'name': '5'},
                {'id': 7, 'name': '6'},
                {'id': 8, 'name': '7'},
                {'id': 9, 'name': '8'},
                {'id': 10, 'name': '9'},
                {'id': 11, 'name': '10 - Thrive under uncertainty'}]"
              :maxItem="11"
              placeholder="Select Uncertainty Appetite"
              v-on:selected="uncertainty_option"
              class="input_boxes"
              id="uncertainty">
            </Dropdown></b-col>
            <b-tooltip target="uncertainty" placement="right">
              Are you generally a person who is unfazed by not knowing everything in advance or do you try to avoid uncertainty at all costs?
            </b-tooltip>
          </b-row>
          <br>
            <div style="border-top: 1px solid black;"><br></div>
          <b-row style="margin-right: 2px">
            <table>
              <tr>
                <p><strong>
                  Please select the most appropriate description from each of the dropdown boxes below that best describe 
                  how much you agree with each statement being CHARACTERISTIC of you:
                </strong></p>
              </tr>
              <tr>
                <td style="width: 43%"><label for="option_1">1: Unforeseen events upset me greatly.</label></td>
                <td><Dropdown
                  :options="[
                    {'id': 1, 'name': 'Not at all', 'opt': 1},
                    {'id': 2, 'name': 'Slightly', 'opt': 1},
                    {'id': 3, 'name': 'Somewhat', 'opt': 1},
                    {'id': 4, 'name': 'Very', 'opt': 1},
                    {'id': 5, 'name': 'Entirely', 'opt': 1}]"
                  :maxItem="5"
                  placeholder="Select Description"
                  v-on:selected="characteristic_options"
                  class="input_boxes"
                  id="option_1">
                </Dropdown></td>
              </tr>
              <tr>
                <td><label for="option_2">2: It frustrates me not having all the information I need.</label></td>
                <td><Dropdown
                  :options="[
                    {'id': 1, 'name': 'Not at all', 'opt': 2},
                    {'id': 2, 'name': 'Slightly', 'opt': 2},
                    {'id': 3, 'name': 'Somewhat', 'opt': 2},
                    {'id': 4, 'name': 'Very', 'opt': 2},
                    {'id': 5, 'name': 'Entirely', 'opt': 2}]"
                  :maxItem="5"
                  placeholder="Select Description"
                  v-on:selected="characteristic_options"
                  class="input_boxes"
                  id="option_2">
                </Dropdown></td>
              </tr>
              <tr>
                <td><label for="option_4">3. Uncertainty keeps me from living a full life.</label></td>
                <td><Dropdown
                  :options="[
                    {'id': 1, 'name': 'Not at all', 'opt': 3},
                    {'id': 2, 'name': 'Slightly', 'opt': 3},
                    {'id': 3, 'name': 'Somewhat', 'opt': 3},
                    {'id': 4, 'name': 'Very', 'opt': 3},
                    {'id': 5, 'name': 'Entirely', 'opt': 3}]"
                  :maxItem="5"
                  placeholder="Select Description"
                  v-on:selected="characteristic_options"
                  class="input_boxes"
                  id="option_3">
                </Dropdown></td>
              </tr>
              <tr>
                <td><label for="option_4">4. One should always look ahead so as to avoid surprises.</label></td>
                <td><Dropdown
                  :options="[
                    {'id': 1, 'name': 'Not at all', 'opt': 4},
                    {'id': 2, 'name': 'Slightly', 'opt': 4},
                    {'id': 3, 'name': 'Somewhat', 'opt': 4},
                    {'id': 4, 'name': 'Very', 'opt': 4},
                    {'id': 5, 'name': 'Entirely', 'opt': 4}]"
                  :maxItem="5"
                  placeholder="Select Description"
                  v-on:selected="characteristic_options"
                  class="input_boxes"
                  id="option_4">
                </Dropdown></td>
              </tr>
              <tr>
                <td><label for="option_5">5: A small unforeseen event can spoil everything, even with the best of planning.</label></td>
                <td><Dropdown
                  :options="[
                    {'id': 1, 'name': 'Not at all', 'opt': 5},
                    {'id': 2, 'name': 'Slightly', 'opt': 5},
                    {'id': 3, 'name': 'Somewhat', 'opt': 5},
                    {'id': 4, 'name': 'Very', 'opt': 5},
                    {'id': 5, 'name': 'Entirely', 'opt': 5}]"
                  :maxItem="5"
                  placeholder="Select Description"
                  v-on:selected="characteristic_options"
                  class="input_boxes"
                  id="option_5">
                </Dropdown></td>
              </tr>
              <tr>
                <td><label for="option_6">6. When it’s time to act, uncertainty paralyses me.</label></td>
                <td><Dropdown
                  :options="[
                    {'id': 1, 'name': 'Not at all', 'opt': 6},
                    {'id': 2, 'name': 'Slightly', 'opt': 6},
                    {'id': 3, 'name': 'Somewhat', 'opt': 6},
                    {'id': 4, 'name': 'Very', 'opt': 6},
                    {'id': 5, 'name': 'Entirely', 'opt': 6}]"
                  :maxItem="5"
                  placeholder="Select Description"
                  v-on:selected="characteristic_options"
                  class="input_boxes"
                  id="option_6">
                </Dropdown></td>
              </tr>
              <tr>
                <td><label for="option_7">7. When I am uncertain I can’t function very well.</label></td>
                <td><Dropdown
                  :options="[
                    {'id': 1, 'name': 'Not at all', 'opt': 7},
                    {'id': 2, 'name': 'Slightly', 'opt': 7},
                    {'id': 3, 'name': 'Somewhat', 'opt': 7},
                    {'id': 4, 'name': 'Very', 'opt': 7},
                    {'id': 5, 'name': 'Entirely', 'opt': 7}]"
                  :maxItem="5"
                  placeholder="Select Description"
                  v-on:selected="characteristic_options"
                  class="input_boxes"
                  id="option_7">
                </Dropdown></td>
              </tr>
              <tr>
                <td><label for="option_8">8. I always want to know what the future has in store for me.</label></td>
                <td><Dropdown
                  :options="[
                    {'id': 1, 'name': 'Not at all', 'opt': 8},
                    {'id': 2, 'name': 'Slightly', 'opt': 8},
                    {'id': 3, 'name': 'Somewhat', 'opt': 8},
                    {'id': 4, 'name': 'Very', 'opt': 8},
                    {'id': 5, 'name': 'Entirely', 'opt': 8}]"
                  :maxItem="5"
                  placeholder="Select Description"
                  v-on:selected="characteristic_options"
                  class="input_boxes"
                  id="option_8">
                </Dropdown></td>
              </tr>
              <tr>
                <td><label for="option_9">9. I can’t stand being taken by surprise.</label></td>
                <td><Dropdown
                  :options="[
                    {'id': 1, 'name': 'Not at all', 'opt': 9},
                    {'id': 2, 'name': 'Slightly', 'opt': 9},
                    {'id': 3, 'name': 'Somewhat', 'opt': 9},
                    {'id': 4, 'name': 'Very', 'opt': 9},
                    {'id': 5, 'name': 'Entirely', 'opt': 9}]"
                  :maxItem="5"
                  placeholder="Select Description"
                  v-on:selected="characteristic_options"
                  class="input_boxes"
                  id="option_9">
                </Dropdown></td>
              </tr>
              <tr>
                <td><label for="option_10">10. The smallest doubt can stop me from acting.</label></td>
                <td><Dropdown
                  :options="[
                    {'id': 1, 'name': 'Not at all', 'opt': 10},
                    {'id': 2, 'name': 'Slightly', 'opt': 10},
                    {'id': 3, 'name': 'Somewhat', 'opt': 10},
                    {'id': 4, 'name': 'Very', 'opt': 10},
                    {'id': 5, 'name': 'Entirely', 'opt': 10}]"
                  :maxItem="5"
                  placeholder="Select Description"
                  v-on:selected="characteristic_options"
                  class="input_boxes"
                  id="option_10">
                </Dropdown></td>
              </tr>
              <tr>
                <td><label for="option_11">11. I should be able to organize everything in advance.</label></td>
                <td><Dropdown
                  :options="[
                    {'id': 1, 'name': 'Not at all', 'opt': 11},
                    {'id': 2, 'name': 'Slightly', 'opt': 11},
                    {'id': 3, 'name': 'Somewhat', 'opt': 11},
                    {'id': 4, 'name': 'Very', 'opt': 11},
                    {'id': 5, 'name': 'Entirely', 'opt': 11}]"
                  :maxItem="5"
                  placeholder="Select Description"
                  v-on:selected="characteristic_options"
                  class="input_boxes"
                  id="option_11">
                </Dropdown></td>
              </tr>
              <tr>
                <td><label for="option_12">12. I must get away from all uncertain situations</label></td>
                <td><Dropdown
                  :options="[
                    {'id': 1, 'name': 'Not at all', 'opt': 12},
                    {'id': 2, 'name': 'Slightly', 'opt': 12},
                    {'id': 3, 'name': 'Somewhat', 'opt': 12},
                    {'id': 4, 'name': 'Very', 'opt': 12},
                    {'id': 5, 'name': 'Entirely', 'opt': 12}]"
                  :maxItem="5"
                  placeholder="Select Description"
                  v-on:selected="characteristic_options"
                  class="input_boxes"
                  id="option_12">
                </Dropdown></td>
              </tr>
          </table>
          <div style="border-top: 1px solid black; margin-top: 10px; padding-top: 10px">  
            <ol type="1">
              <li>
                <b-row>
                  <p>If you're running a race and you pass the person in second place, what place are you in?</p>
                </b-row>
                <b-row>
                  <p><input v-model="diction.crt1" class="crt_input" @keypress="isNumber($event)" style="text-align: right;"/> {{ check_place() }} place (number(s) only)</p>
                </b-row>
              </li>
              <li>
                <b-row>
                  <p>A farmer had 15 sheep and all but 8 died. How many are left?</p>
                </b-row>
                <b-row>
                  <p><input v-model="diction.crt2" class="crt_input" @keypress="isNumber($event)" style="text-align: right;"/> sheep (number(s) only)</p>
                </b-row>
              </li>
              <li>
                <b-row>
                  <p>Emily's father has three daughters. The first two are named April and May. What is the third daughter's name?</p>
                </b-row>
                <b-row>
                  <p><input v-model="diction.crt3" class="crt_input" style="text-align: right;"/></p>
                </b-row>
              </li>
              <li>
                <b-row>
                  <p>How many cubic meters of dirt are there in a hole that is 3m deep x 3m wide x 3m long?</p>
                </b-row>
                <b-row>
                  <p><input v-model="diction.crt4" class="crt_input" @keypress="isNumber($event)" style="text-align: right;"/> cubic meters (number(s) only)</p>
                </b-row>
              </li>
            </ol>
          </div>
          </b-row>
          <div id='id_proceed' style="width: 140px;" :disabled="!checkFilled(diction)">
            <b-button  class="proceed" :disabled="checkFilled(diction) || !enabled" v-on:click="proceed">Proceed</b-button>
          </div>
          <b-tooltip target="id_proceed" placement="right">You have to complete all the fields before you can proceed.</b-tooltip>
        </div>
      </b-col>
    </b-row>
    <exitpanel/>
    <p style="color:blue; position:fixed; right:3%; top:3%;">Alias: {{this.$alias}}</p>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import idxbox from "@/components/index_box.vue";
import exitpanel from "@/components/exit_panel.vue";
import Dropdown from "@/components/dropdown.vue";
import axios from 'axios';

export default {
  name: "Charity",
  mixins: [common],
  components: {
    exitpanel,
    idxbox,
    Dropdown
  },
  data() {
    return {
      diction: {
        charity: null,
        risk: null,
        uncertainty: null,
        characteristics: {
          1: null,
          2: null,
          3: null,
          4: null,
          5: null,
          6: null,
          7: null,
          8: null,
          9: null,
          10: null,
          11: null,
          12: null
        },
        crt1: null,
        crt2: null,
        crt3: null,
        crt4: null
      },
      crtTotal: 0,
      player_id: null,
      enabled: false
    }
  },
  methods: {
    handleWheel() {
      window.addEventListener('wheel', function(event) {
        if (event.deltaY < 0) {
          window.scrollTo(0, window.top.scrollY-200.0)
        } else if (event.deltaY > 0) {
          window.scrollTo(0, window.top.scrollY+200.0)
        }
      })
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    charity_option(v) {
      this.diction.charity = v.name
    },
    risk_option(v) {
      this.diction.risk = v.id
    },
    uncertainty_option(v) {
      this.diction.uncertainty = v.id
    },
    characteristic_options(v) {
      this.diction.characteristics[v.opt] = v.id
    },
    checkFilled(obj) {
      for (var key in obj) {
        if (key === 'characteristics') {
          for (let i = 1; i < 13; i++) { 
            if (!obj[key][i]){
              return true
            }
          }
        } else if (!obj[key]) {
          return true;
        }
      }
      return false;
    },
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    check_place() {
      switch(this.diction.crt1) {
        case null:
          return ''
        case '':
          return ''
        case '1':
          return 'st'
        case '2':
          return 'nd'
        case '3':
          return 'rd'
        default:
          return 'th'
      }
    },
    calc_crt() {
      if (this.diction.crt1 == '2') {
        this.crtTotal += 1
      }
      if (this.diction.crt2 == '8') {
        this.crtTotal += 1
      }
      let temp_str= this.diction.crt3.toLowerCase()
      if (temp_str.slice(0, 3) == 'emi') {
        this.crtTotal += 1
      }
      if (this.diction.crt4 == '0') {
        this.crtTotal += 1
      }
    },
    proceed () {
      const path = 'send_to_db';
      axios.get(path, {
          params: {
            table: 'Table_Time',
            data: {
              Player_ID: [this.player_id],
              Charity: [this.getTime()]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
      
      this.calc_crt()
      axios.get(path, {
          params: {
            table: 'Table_Participants',
            data: {
              Player_ID: [this.player_id],
              Charity: [this.diction.charity],
              CRT_Total: [this.crtTotal]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
      
      const path_games = 'send_to_db';
      axios.get(path_games, {
          params: {
            table: 'Table_Games',
            data: {
              Player_ID: [this.player_id],
              Gen_Risk_Appetite: [this.diction.risk], 
              Gen_Uncertainty_Appetite: [this.diction.uncertainty], 
              Uncert_Q1: [this.diction.characteristics[1]],
              Uncert_Q2: [this.diction.characteristics[2]],
              Uncert_Q3: [this.diction.characteristics[3]],
              Uncert_Q4: [this.diction.characteristics[4]],
              Uncert_Q5: [this.diction.characteristics[5]],
              Uncert_Q6: [this.diction.characteristics[6]],
              Uncert_Q7: [this.diction.characteristics[7]],
              Uncert_Q8: [this.diction.characteristics[8]],
              Uncert_Q9: [this.diction.characteristics[9]],
              Uncert_Q10: [this.diction.characteristics[10]],
              Uncert_Q11: [this.diction.characteristics[11]],
              Uncert_Q12: [this.diction.characteristics[12]],
              CRT1: [this.diction.crt1],
              CRT2: [this.diction.crt2],
              CRT3: [this.diction.crt3],
              CRT4: [this.diction.crt4]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
      
      this.$router.push("/game1intro")
    }
  },
  created() {
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
  },
  BeforeUnloadEvent() {
    window.removeEventListener('wheel', function(event) {
      if (event.deltaY < 0) {
        window.scrollTo(0, window.top.scrollY-100.0)
      } else if (event.deltaY > 0) {
        window.scrollTo(0, window.top.scrollY+100.0)
      }
    })
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id != 'None') {
        this.enabled = true 
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

.input_boxes {
  width: 30%
}

</style>
