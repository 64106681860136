<template>
  <div>
    <div class="centered" style="margin: 10%">
      <h1 style="color:blue">The Money Laundering Simulation Game</h1> 
      <h2>Sorry to see you leave so soon, as things were just about to become interesting!</h2>
      <h2>Thank you for considering participating.</h2>
      <br>
      <p>You can always continue later by re-entering the password and your alias: <span style="color:blue;">{{ $alias }}</span></p>
      <b-button class="goodbye_button proceed" @click="leave">Goodbye</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Goodbye1",
  methods: {
    leave() {
      window.location.href = "https://www.google.com"
    }  
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

.goodbye_button{
  width: 10%;
  margin-top: 5%;
}
</style>
