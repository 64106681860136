<template>
  <b-container :key="refresh">
    <b-row>
      <div style="margin-left: 10px">
        <h1>Admin</h1>
      </div>
      <b-col>
        <div>
          <div class="message_box">
            <h4>View level/day page</h4>
            <p>Please select the level and day of said participant you would like to view. (note that you will not be able to make any changes)</p>
            <div class="aligned_div">
              <label class="label_select" for="alias">Alias</label>
              <Dropdown 
                :options="aliases"
                :maxItem="5"
                placeholder="Choose alias..."
                v-on:selected="select_alias"
                class="levels_select"
                id="alias"/>
            </div>
            <div class="aligned_div">
              <label class="label_select" for="levels">Level</label>
              <Dropdown 
                :key="changed_alias"
                :options="levels"
                :maxItem="$all_variables['Var_Levels']+1"
                placeholder="Select level..."
                v-on:selected="select_level"
                class="levels_select"
                id="levels"/>
            </div>
            <div class="aligned_div">
              <label class="label_select" for="days">Day</label>
              <Dropdown
                :key="`${changed_alias}-${changed_level}`"
                :options="days"
                :maxItem="$all_variables['Var_Days']+1"
                placeholder="Select day..."
                v-on:selected="select_day"
                class="levels_select"
                id="days"/>
            </div>
            <div class="aligned_div" style="float: right;">
              <label class="label_select" for="view"></label>
              <b-button class="levels_select button_view" id="view" @click="navigate_page()">View</b-button>
            </div>
          </div>
        </div>
      </b-col>
      <b-col></b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import Dropdown from "@/components/dropdown.vue";
import axios from 'axios';

export default {
  name: "Admin",
  mixins: [common],
  components: {
    Dropdown
  },
  data () {
    return {
      levels: [],
      days: [],
      aliases: [],
      level_selected: null,
      day_selected: null,
      alias_selected: null,
      changed_alias: false,
      changed_level: false,
      refresh: false,
      fetched: false
    }
  },
  methods: {
    populate_vars() {
      this.filtered_days = this.days
      const path = '/admin/fetch_completed';
      axios.get(path)
        .then((res) => {
          let result = res.data.diction;
          let unique_aliases = [...new Map(result.map(item =>
            [item['Alias'], item['Alias']])).values()].sort((a, b) => a - b);

          let temp_alias_data = []
          let temp_level_data = []
          let temp_days_data = []
          let temp_levels = []
          let temp_days = []
          let level_data = []
          for (let i=0; i<unique_aliases.length; i++) {
            temp_alias_data = result.filter(function(a) {
              return a.Alias == unique_aliases[i]
            })

            temp_levels = [...new Map(temp_alias_data.map(item =>
              [item['Level'], item['Level']])).values()].sort((a, b) => a - b);

            level_data = []
            for (let l=0; l<temp_levels.length; l++) {
              temp_level_data = temp_alias_data.filter(function(a) {
                return a.Level == temp_levels[l]
              })

              temp_days = [...new Map(temp_level_data.map(item =>
                [item['Day'], item['Day']])).values()].sort((a, b) => a - b)

              temp_days_data = []
              for (let d=0; d<temp_days.length; d++) {
                temp_days_data.push({
                  id: d+1,
                  name: temp_days[d].toString()
                })
              }

              level_data.push({
                id: l+1,
                name: temp_levels[l].toString(),
                days: temp_days_data
              })
            }
            this.aliases.push({
              id: i+1,
              name: unique_aliases[i],
              levels: level_data
            })
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    select_alias(v) {
      if ('levels' in v) {
        if (this.alias_selected !== v.name) {
          this.changed_alias = !this.changed_alias
          this.days = []
        }
        this.levels = v.levels
        this.alias_selected = v.name
        Vue.prototype.$alias = v.name
      }
    },
    select_level(v) {
      if ('days' in v) {
        if (this.level_selected !== parseInt(v.name)) {
          this.changed_level = !this.changed_level
        }
        this.days = v.days
        this.level_selected = parseInt(v.name)
        Vue.prototype.$level = parseInt(v.name)
      }
    },
    select_day(v) {
      this.day_selected = parseInt(v.name)
      Vue.prototype.$day = parseInt(v.name)
    },
    navigate_page() {
      if (!isNaN(this.level_selected) && !isNaN(this.day_selected) && this.alias_selected !== undefined) {
        if (this.level_selected == 0) {
          this.$router.push("/mlpracticelevel")
        } else if (this.level_selected > 0) {
          this.$router.push("/launderinglevels")
        }
      }
    }
  },
  watch: {
    fetched: function() {
      if (this.$all_variables && this.$all_variables !== 'None') {
        this.populate_vars()
        this.refresh = !this.refresh
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";


.levels_select {
  display: block;
  margin: 0px;
  height: 1;
}

.label_select {
  font-size: 70%;
}

.aligned_div {
  width: 20%;
  padding: 0.2%;
  display: inline-block;
}

.button_view {
  height: 20px;
  padding: 0px;
  width: 100%;
  font-size: .8em;
}

::v-deep .dropdown-input {
  height: 20px;
}

</style>
