<template>
  <b-container :key="refreshing">
    <b-row>
      <h3 style="color: blue;">GAME 1: Results</h3>
    </b-row>
    <b-row>
      <div class="message_box">
        <b-row>
          <br>
          <warehouse :slider_value="slider_value" :exploding_crate="random_num"/>
        </b-row>
        <b-row>
            <b-col>
                <p>Remaining parcels: {{ remaining_parcels }}</p>
            </b-col>
            <b-col>
                <b-row>
                    <b-col cols="4"></b-col>
                    <b-col>
                        <b-row rows="1">
                            <b-col><p>Parcels to be delivered: </p></b-col>
                            <b-col><p>{{ delivered_parcels }}</p></b-col>
                        </b-row>
                        <b-row rows="1">
                            <b-col><p>Delivery fee per parcel: </p></b-col>
                            <b-col><p>{{ financial($all_variables['Var_DeliveryFee']) }}</p></b-col>
                        </b-row>
                        <b-row rows="1">
                            <b-col><p><span style="color: blue;">ACTUAL</span> earnings: </p></b-col>
                            <b-col><p class="top-bot-border">{{ financial(delivered_parcels * $all_variables['Var_DeliveryFee']) }}</p></b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
      </div>
    </b-row>
    <b-button  class="proceed" v-on:click="proceed" :disabled="!enabled">Proceed to Game 2</b-button>
    <exitpanel/>
  <p style="color:blue; position:fixed; right:3%; top:3%;">Alias: {{this.$alias}}</p>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import exitpanel from "@/components/exit_panel.vue";
import warehouse from "@/components/warehouse.vue";
import axios from 'axios';

export default {
  name: "Game1Results",
  mixins: [common],
  components: {
    exitpanel,
    warehouse
  },
  data() {
    return {
      slider_value: -1,
      random_num: null,
      remaining_parcels: null,
      delivered_parcels: null,
      player_id: null,
      enabled: false,
      refreshing: false
    }
  },
  methods: {
    financial(x) {
        return Number.parseFloat(x).toFixed(2);
    },
    generate_random_number() {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_Games',
              columns: ['Bomb_Game1'],
              match_col: ['Player_ID'],
              match_val: [this.player_id],
              match_case: ['WHERE']
            }
          }
        })
        .then((res) => {
          let result = JSON.parse(res.data.data)[0]['Bomb_Game1'];
          if (result == null) {
            this.random_num = Math.floor(Math.random() * Math.floor(100)) + 1;
          } else { 
            this.random_num = result; 
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    proceed(){
      const path_consent = 'send_to_db';
      axios.get(path_consent, {
          params: {
            table: 'Table_Time',
            data: {
              Player_ID: [this.player_id],
              Game1Results: [this.getTime()]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      this.$router.push("/game2intro")
    },
    getVariables() {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_Games',
              columns: ['Slider_Game1'],
              match_col: ['Player_ID'],
              match_val: [this.player_id],
              match_case: ['WHERE']
            }
          }
        })
        .then((res) => {
          let result = JSON.parse(res.data.data);
          if (isNaN(result[0]['Slider_Game1']) || result[0]['Slider_Game1'] == 'None' || result[0]['Slider_Game1'] == null) {
            this.slider_value = 0
          } else {
            this.slider_value = result[0]['Slider_Game1']
          }
          if (this.random_num < this.slider_value) {
            this.remaining_parcels = 100 - this.slider_value
            this.delivered_parcels = 0  
          } else {
            this.delivered_parcels = this.slider_value
            this.remaining_parcels = 0
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    }
  },
  created() {
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id !== 'None') {
        this.generate_random_number()
        this.enabled = true
      }
    },
    random_num: function() {
      if (this.random_num && this.random_num !== 'None') {
        this.getVariables()
      }
    },
    slider_value: function() {
      if (!isNaN(this.slider_value) && this.slider_value !== 'None') {
        const path_send = 'send_to_db';
        axios.get(path_send, {
            params: {
              table: 'Table_Games',
              data: {
                Player_ID: [this.player_id],
                Bomb_Game1: [this.random_num],
                Winnings_Game1: [this.delivered_parcels * this.$all_variables['Var_DeliveryFee']],
              }
            }
          })
          .then((res) => {
            res.status;
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
        this.refreshing = !this.refreshing
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

</style>
