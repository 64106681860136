<template>
  <div>
        <div style="width: 30%; margin-left: 5%;">
            <div class="triangle-up"></div>
        </div>
        <div class="container" style="margin-left: 5%;">
            <img alt="Unknown Parcels container" src="../assets/question_mark.png" class="image overlay" v-show="game2">
            <img alt="Exploding Container" src="../assets/bomb.png" class="image overlay" v-if="(exploding_crate>slider_value && exploding_crate!==null)">
            <table id="warehouse">
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(1) }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(2) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(3) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(4) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(5) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(6) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(7) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(8) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(9) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(10) }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(11) }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(12) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(13) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(14) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(15) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(16) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(17) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(18) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(19) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(20) }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(21) }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(22) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(23) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(24) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(25) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(26) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(27) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(28) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(29) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(30) }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(31) }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(32) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(33) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(34) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(35) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(36) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(37) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(38) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(39) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(40) }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(41) }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(42) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(43) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(44) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(45) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(46) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(47) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(48) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(49) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(50) }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(51) }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(52) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(53) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(54) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(55) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(56) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(57) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(58) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(59) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(60) }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(61) }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(62) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(63) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(64) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(65) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(66) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(67) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(68) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(69) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(70) }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(71) }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(72) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(73) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(74) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(75) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(76) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(77) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(78) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(79) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(80) }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(81) }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(82) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(83) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(84) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(85) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(86) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(87) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(88) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(89) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(90) }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(91) }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(92) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(93) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(94) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(95) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(96) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(97) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(98) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(99) }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': !isCrate(100) }"></td>
                </tr>
            </tbody>
            </table>
        </div>
        <div class="container">
            <div class="arrow-right"></div>
        </div>
        <div class="container">
            <img alt="Exploding Container" src="../assets/bomb.png" class="image overlay" v-if="(exploding_crate<=slider_value && exploding_crate!==null)">
            <table>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(91), 'opacity_game2': game2 }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(92), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(93), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(94), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(95), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(96), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(97), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(98), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(99), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(100), 'opacity_game2': game2 }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(81), 'opacity_game2': game2 }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(82), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(83), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(84), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(85), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(86), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(87), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(88), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(89), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(90), 'opacity_game2': game2 }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(71), 'opacity_game2': game2 }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(72), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(73), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(74), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(75), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(76), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(77), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(78), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(79), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(80), 'opacity_game2': game2 }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(61), 'opacity_game2': game2 }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(62), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(63), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(64), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(65), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(66), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(67), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(68), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(69), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(70), 'opacity_game2': game2 }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(51), 'opacity_game2': game2 }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(52), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(53), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(54), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(55), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(56), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(57), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(58), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(59), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(60), 'opacity_game2': game2 }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(41), 'opacity_game2': game2 }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(42), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(43), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(44), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(45), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(46), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(47), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(48), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(49), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(50), 'opacity_game2': game2 }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(31), 'opacity_game2': game2 }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(32), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(33), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(34), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(35), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(36), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(37), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(38), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(39), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(40), 'opacity_game2': game2 }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(21), 'opacity_game2': game2 }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(22), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(23), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(24), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(25), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(26), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(27), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(28), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(29), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(30), 'opacity_game2': game2 }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(11), 'opacity_game2': game2 }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(12), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(13), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(14), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(15), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(16), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(17), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(18), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(19), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(20), 'opacity_game2': game2 }"></td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                <th style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(1), 'opacity_game2': game2 }"></th>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(2), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(3), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(4), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(5), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(6), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(7), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(8), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(9), 'opacity_game2': game2 }"></td>
                <td style="background-color: white;" v-bind:class="{ 'crate-placeholder': isCrate(10), 'opacity_game2': game2 }"></td>
                </tr>
            </tbody>
            </table>
        </div>
    <div class="cont_text" style="margin-left: 5%;">
        <p class="descript_text">Warehouse</p>
    </div>
    <div class="cont_text"></div>
    <div class="cont_text">
        <p class="descript_text">Your delivery container</p>
    </div>
    <p>Random parcel containing bomb: <span style="color: blue;">{{exploding_crate}}</span></p>
  </div>
</template>

<script>
export default {
  name: "warehouse",
  props: {
    slider_value: {
      type: Number,
      required: true,
      default: 0
    },
    exploding_crate: {
        type: Number,
        required: false,
        default: null
    },
    game2: {
        type: Boolean,
        required: false,
        default: false
    }
  },
  methods: {
    isCrate(v) {
        return this.slider_value >= v
    },
    game2_opacity() {
        if (this.game2) {
            return 0.5
        } else {
            return 1
        }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

tr , th , td  {
    border: 2px solid black;
    width: 1%;
    height: 1%;
}

table {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    width: 20vw;
    height: 20vw;
    max-width: 200px;
    max-height: 200px;
    margin-left: auto;
    margin-right: auto;
}

.triangle-up {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #555;
    z-index: 1;
    width: 25vw;
    height: 5vw;
    max-width: 250px;
    max-height: 50px;
    margin-right: auto;
    margin-left: auto;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
}

.arrow-right {
    border: solid green;
    border-width: 0 5px 5px 0;
    width: 10vw;
    height: 10vw;
    max-width: 100px;
    max-height: 100px;
    margin-left: auto;
    margin-right: auto;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.crate-placeholder {
    background-color: green !important;
}

.opacity_game2 {
    opacity: 0.5;
}

.image {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 20vw;
    height: 20vw;
    max-width: 200px;
    max-height: 200px;
    margin-left: auto;
    margin-right: auto;
    z-index: 4
}

.overlay {
  position: absolute;
}

.descript_text {
    vertical-align: top;
    text-align: center;
    font-size: 1vw;
    font-size: clamp(6px,2vw,20px)
}

.cont_text {
    display: inline-block;
    position: relative;
    width: 30%;
    text-align: top;
}

.container {
    display: inline-block;
    position: relative;
    height: 20vw;
    width: 30%;
    max-height: 200px;
}

</style>