<template>
  <div class="404">
    <br />
    <h1>(404) Page Not Found</h1>
    <br /><br /><br />
    <a>
      <img alt="Sad Enriched Database" src="../../assets/sad-db.png"  />
    </a>
  </div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

img, h1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
