<template>
  <b-container>
    <b-row>
      <div class="message_box">
        <idxbox pos = 5 />
      </div>
    </b-row>
    <b-row>
      <div class="message_box">
        <b-row>
          <p v-html="replace_vars_in_text(info1)"></p>
        </b-row>
        <b-row>
          <img alt="Diagram_1" src="../../assets/diagrams/objective_diagram.png" class="centered" />
        </b-row>
        <b-row>
          <p v-html="replace_vars_in_text(info2)"></p>
        </b-row>
      </div>
    </b-row>
    <div id='id_proceed' style="width: 10%; min-width: 140px" :disabled="!timeleft > 0 || !enabled">
      <b-button :disabled="timeleft > 0 || !enabled" class="proceed" v-on:click="proceed">Proceed</b-button>
    </div>
    <b-tooltip target="id_proceed" placement="right">Please spend some time to visualise what you will do with so much money. <br> Button will be enabled shortly</b-tooltip>
    <exitpanel :screennum="2"/>
  <p style="color:blue; position:fixed; right:3%; top:3%;">Alias: {{this.$alias}}</p>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import idxbox from "@/components/index_box.vue";
import exitpanel from "@/components/exit_panel.vue";
import axios from 'axios';

export default {
  name: "Objective",
  mixins: [common],
  components: {
    idxbox,
    exitpanel
  },
  data() {
    return {
      timeleft: 0,
      info1: '',
      info2: '',
      player_id: null,
      enabled: false
    }
  },
  methods: {
    start_timer() {
      this.timeleft = this.$all_variables['Var_ObjectiveTime']
      this.timeoutTimer = setInterval(this.set_timeout, 1000);
    },
    set_timeout() {
      if(this.timeleft <= 0){
        clearInterval(this.timeoutTimer);
        this.timeleft += 1
      }
      this.timeleft -= 1;
    },
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    proceed () {
      const path = 'send_to_db';
      axios.get(path, {
          params: {
            table: 'Table_Time',
            data: {
              Player_ID: [this.player_id],
              Objective: [this.getTime()]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      this.$router.push("/mlpracintro")
    },
    getVariables() {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_MessageBoxes',
              columns: ['Variable_name', 'Info/Button_description'],
              match_col: ['Variable_name', 'Variable_name'],
              match_val: ['Info_objective1', 'Info_objective2'],
              match_case: ['WHERE', 'OR']
            }
          }
        })
        .then((res) => {
          this.info1 = JSON.parse(res.data.data)[0]['Info/Button_description'];
          this.info2 = JSON.parse(res.data.data)[1]['Info/Button_description'];
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    }
  },
  created() {
    this.getVariables()
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id != 'None') {
        this.start_timer()
        this.enabled = true
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

</style>
