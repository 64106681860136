<template>
  <b-container>
    <b-row>
      <div class="message_box">
        <idxbox pos = 9 />
      </div>
    </b-row>
    <b-row>
      <div class="message_box">
        <h4 v-html="replace_vars_in_text(msg_thanks)"></h4>
        <p>
          You have contributed a total of <span style="color: blue;"><strong>{{financial(winnings_game1 + winnings_game2 + winnings_ml)}}</strong></span> 
          towards the draw for <span style="color: blue;"><strong>{{ charity }}</strong></span>, 
          as your chosen charity to support. Thank you so much!
        </p>
        <p>The total draw contribution was made up as follows:</p>
        <table style="width: 30%;">
          <tr style="border: 1px dotted black;">
            <td>Risk - Game 1:</td>
            <td style="text-align: right; border-left: 1px dotted black;">{{ financial(winnings_game1) }}</td>
          </tr>
          <tr style="border: 1px dotted black;">
            <td>Uncertainty - Game 2:</td>
            <td style="text-align: right; border-left: 1px dotted black;">{{ financial(winnings_game2) }}</td>
          </tr>
          <tr style="border: 1px dotted black;">
            <td>Money Laundering - Simulation:</td>
            <td style="text-align: right; border-left: 1px dotted black;"> {{ financial(winnings_ml) }}</td>
          </tr>
          <tr style="border: 1px dotted black;">
            <td></td>
            <td 
              style="
                border-top: 1px solid black;
                border-bottom: 2px solid black;
                border-left: 1px dotted black;
                width: 20%; 
                text-align: right;">
              {{ winnings_game1 + winnings_game2 + winnings_ml }}</td>
          </tr>
        </table>
      </div>
      <div class="message_box">
        <p v-html="replace_vars_in_text(msg_rw)"></p>
        <textarea rows="4" cols="50" style="width: 100%" placeholder="In your opnion..."></textarea>
        <p></p>
        <!--
        <p>
          If you are willing to be contacted for a brief interview regarding the simulation game and your experience 
          thereof, please enter your cell phone number in the field below, starting with your country code:
        </p>
        <table style="width: 30%">
          <tr>
            <td>Cell phone nr:</td>
            <td><input placeholder="+## ## ###-####"></td>
          </tr>
        </table>
        <p></p>
        -->
      </div>
      <div class="message_box">
        <p v-html="replace_vars_in_text(msg_encourage)"></p>
        <b-col cols="4">
          <!-- <b-button @click="donwload_info()" class="download_button">Download participant information sheet<br>(optional)</b-button> -->
          <b-button class="download_button" :href="`${publicPath}PARTICIPANT INFORMATION SHEET.pdf`" download="PARTICIPANT INFORMATION SHEET">
              Download participant information sheet<br>(optional)
          </b-button>
        </b-col>
      </div>
      <div class="message_box">
        <p v-html="replace_vars_in_text(msg_repeat)"></p>
      </div>
      </b-row>
    <b-button class="goodbye_button" @click="leave">Goodbye</b-button>
  <p style="color:blue; position:fixed; right:3%; top:3%;">Alias: {{this.$alias}}</p>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import idxbox from "@/components/index_box.vue";
import axios from 'axios';

export default {
  name: "Goodbye2",
  mixins: [
    common
  ],
  components: {
    idxbox
  },
  data() {
    return {
      timeleft: 0,
      player_id: null,
      enabled: false,
      charity: null,
      charity_winnings: null,
      winnings_game1: 0,
      winnings_game2: 0,
      winnings_ml: 0,
      msg_encourage: '',
      msg_rw: '',
      msg_repeat: '',
      msg_thanks: '',
      publicPath: process.env.BASE_URL
    }
  },
  methods: {
    leave() {
      window.location.href = "https://www.google.com"
    },
    get_messages() {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_MessageBoxes',
              columns: ['Variable_name', 'Info/Button_description'],
              match_col: ['Variable_name', 'Variable_name', 'Variable_name', 'Variable_name'],
              match_val: ['Info_Encourage', 'Info_Realworld', 'Info_Repeat', 'Info_Thanks'],
              match_case: ['WHERE', 'OR', 'OR', 'OR']
            }
          }
        })
        .then((res) => {
          let result = JSON.parse(res.data.data)
          this.msg_encourage = result[0]['Info/Button_description'];
          this.msg_rw = result[1]['Info/Button_description'];
          this.msg_repeat = result[2]['Info/Button_description'];
          this.msg_thanks = result[3]['Info/Button_description'];
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    getVariables() {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_Participants',
              columns: ['Charity', 'Charity_Total_Winnings'],
              match_col: ['Player_ID'],
              match_val: [this.player_id],
              match_case: ['WHERE']
            }
          }
        })
        .then((res) => {
          this.charity = JSON.parse(res.data.data)[0]['Charity'];
          this.charity_winnings = JSON.parse(res.data.data)[0]['Charity_Total_Winnings'];
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
      axios.get(path, {
          params: {
            data: {
              table: 'Table_Player_Results',
              columns: ['Winnings_ML'],
              match_col: ['Player_ID'],
              match_val: [this.player_id],
              match_case: ['WHERE']
            }
          }
        })
        .then((res) => {
          let result = JSON.parse(res.data.data)
          for (let i=0; i < result.length; i++) {
            this.winnings_ml += result[i]['Winnings_ML']
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
      axios.get(path, {
          params: {
            data: {
              table: 'Table_Games',
              columns: ['Winnings_Game1', 'Winnings_Game2'],
              match_col: ['Player_ID'],
              match_val: [this.player_id],
              match_case: ['WHERE']
            }
          }
        })
        .then((res) => {
          let result = JSON.parse(res.data.data)
          if (result.length > 0) {
            this.winnings_game1 = result[0]['Winnings_Game1'];
            this.winnings_game2 = result[0]['Winnings_Game2'];
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    financial(x) {
        if (x != null)
          return Number.parseFloat(x).toFixed(2);
        return ''
    },
  },
  created() {
    this.get_messages()
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id != 'None') {
        this.getVariables()
        this.enabled = true
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

.download_button {
  width: 100%;
  text-align: center;
  vertical-align: middle !important;
  padding: auto;
}


.goodbye_button{
  width: 20%;
  margin-top: 5%;
  margin-bottom: 5%;
}
</style>
