<template>
  <b-container :key="`${$all_variables['Var_DeliveryFee']}`">
    <b-row>
      <h2>The Money Laundering Simulation Game</h2>
      <br>
      <h4 style="color: blue;">GAME 1: Introduction and practice</h4>
      <div class="message_box">
        <h3>Scenario:</h3>
        <p>
          As a part time job you collect parcels from a local warehouse for delivery to a nearby shop and get paid <span style="color: blue;">100 cents for each parcel delivered</span>.
          The warehouse manager usually sends you an SMS to let you know how many parcels he has for delivery the next day and then you let him know how 
          many you will be delivering. The warehouse manager then randomly selects and places these parcels for you in a container to collect the next 
          morning, while also arranging for someone else to deliver the remainder of the parcels stored in a separate container. Luckily you always get 
          to choose first how many you want to deliver!
          <br><br>
          The latest SMS from the warehouse manager indicates he has <span style="color: blue;">100 parcels available</span> for delivery. However, you have heard that 
          <span style="color: blue;">ONE</span> of these parcels contains a <span style="color: blue;">time bomb</span>! Nobody knows which of the parcels it is. 
          The time bomb will detonate during the night and <span style="color: blue;">destroy all the parcels in the container</span> it happens to be in. If the time 
          bomb was in one of the parcels that were <span style="color: blue;">randomly</span> chosen to go into your container, you will have no parcels left to deliver 
          and earn <span style="color: blue;">ZERO</span> income for the day. If, however, the time bomb happens to land up in the other container you get to keep 
          the money for the number of parcels kept aside for you times the delivery fee per parcel. You really, really need the money!
          <br><br>
          </p>
          <h5>The question is - how many parcels do you offer to deliver the next day?</h5>
      </div>
      <br>
    </b-row>
    <b-row>
      <div class="message_box">
        <p class="squeeze-text">
          <strong>Practice</strong> visually moving parcels over from the warehouse to your delivery container for collection the next day by clicking, holding and then 
          dragging the slider below to the right or left in order to transfer more or less of the parcels:
        </p><br>
        <b-row>
          <br>
          <warehouse :slider_value="slider_value"/>
        </b-row>
        <b-row>
            <b-col>
                <p>Remaining parcels: {{ 100-slider_value }}</p>
                <vue-slider v-model="slider_value" />
                <p class="small-text">(Click, hold and drag the slider)</p>
            </b-col>
            <b-col>
                <b-row>
                    <b-col cols="4"></b-col>
                    <b-col>
                        <b-row rows="1">
                            <b-col><p>Parcels to be delivered: </p></b-col>
                            <b-col><p>{{ slider_value }}</p></b-col>
                        </b-row>
                        <b-row rows="1">
                            <b-col><p>Delivery fee per parcel: </p></b-col>
                            <b-col><p>{{ financial($all_variables['Var_DeliveryFee']) }}</p></b-col>
                        </b-row>
                        <b-row rows="1">
                            <b-col><p><span style="color: blue;">POTENTIAL</span> earnings: </p></b-col>
                            <b-col><p class="top-bot-border">{{ financial(slider_value * $all_variables['Var_DeliveryFee']) }}</p></b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
          <br>
          <p class="squeeze-text">
            Remember: The earnings indicated on the right are only potential earnings, as every parcel has an equal chance of containing the bomb. If the bomb happens to 
            be in any of the parcels in your delivery container you will earn zero for your charity in this game!
          </p>
          <br>
        </b-row>
      </div>
    </b-row>
    <b-row>
      <div class="message_box">
        <p class="squeeze-text">
          The above was only to practice moving the parcels around. In order to play the parcel delivery game for real and potentially earn an actual contribution for 
          your charity, click the proceed b-button below. Note: For the real parcel delivery game you will only have {{ $all_variables['Var_Game1Time'] }} seconds to make your decision.
        </p>
      </div>
    </b-row>

    <div id='id_proceed' style="width: 140px;" :disabled="wasMoved">
      <b-button  class="proceed" v-on:click="proceed" :disabled="!enabled || !wasMoved">Proceed</b-button>
    </div>
    <b-tooltip target="id_proceed" placement="right">You have to move the slider before you can proceed.</b-tooltip>
    <exitpanel/>
  <p style="color:blue; position:fixed; right:3%; top:3%;">Alias: {{this.$alias}}</p>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import exitpanel from "@/components/exit_panel.vue";
import warehouse from "@/components/warehouse.vue";
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import axios from 'axios';

export default {
  name: "Game1Intro",
  mixins: [common],
  components: {
    exitpanel,
    warehouse,
    VueSlider
  },
  data() {
    return {
      slider_value: 0,
      wasMoved: false,
      player_id: null,
      enabled: false
    }
  },
  methods: {
    financial(x) {
        return Number.parseFloat(x).toFixed(2);
    },
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    proceed () {
      const path_consent = 'send_to_db';
      axios.get(path_consent, {
          params: {
            table: 'Table_Time',
            data: {
              Player_ID: [this.player_id],
              Game1Intro: [this.getTime()]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      this.$router.push("/game1play")
    }
  },
  created() {
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id != 'None') {
        this.enabled = true
      }
    },
    slider_value: function() {
      this.wasMoved = true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

.squeeze-text {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
