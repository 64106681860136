import { render, staticRenderFns } from "./3.9_Game2Results.vue?vue&type=template&id=069a1345&scoped=true&"
import script from "./3.9_Game2Results.vue?vue&type=script&lang=js&"
export * from "./3.9_Game2Results.vue?vue&type=script&lang=js&"
import style0 from "./3.9_Game2Results.vue?vue&type=style&index=0&id=069a1345&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "069a1345",
  null
  
)

export default component.exports