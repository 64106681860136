import axios from 'axios' 
import moment from 'moment'
import Vue from 'vue'

export default {
  name: "CommonMethods",
  data() {
    return {
      changed: false,
      all_variables: {},
      fetched: false,
      msg_exit1: '',
      msg_exit2: ''
    }
  },
  methods: {
    convertMS_pure(value) {
      const sec = parseInt(value, 10); // convert value to number if it's string
      let minutes = Math.floor((sec) / 60); // get minutes
      let seconds = sec - (minutes * 60); //  get seconds
      if (seconds == 0) {
        return minutes
      } else if (minutes == 0) {
        return seconds
      } else {
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return minutes+':'+seconds; // Return is MM : SS
      }
    },
    replace_vars_in_text(s) {
      if (s != null) {
        let key, val = ''
        for ([key, val] of Object.entries(this.all_variables)) {
          if (s.includes(key)) {
            if (key.includes("Time")) {
              val = this.convertMS_pure(val)
            }
            s = s.replaceAll('['+key+']%', (val*100).toString() + '%')
            s = s.replaceAll('['+key+']', val)
          }
        }
        return s
      } else {
        return s
      }
    },
    get_player_id() {
      console.log("Alias:", this.$alias)
      const path = 'fetch_player_id';
      axios.get(path, {
          params: {
            alias: this.$alias
          }
        })
        .then((res) => {
          this.player_id = res.data.player_id;
          console.log("Player:", this.player_id)
          return this.player_id
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    navigate_save(player_id) {
      let routes = this.$router.options.routes
      const path = 'fetch_from_db';
      axios.get(path, {
        params: {
          data: {
            table: 'Table_Time',
            columns: ['*'],
            match_col: ['Player_ID'],
            match_val: [player_id],
            match_case: ['WHERE']
          }
        }
      })
      .then((res) => {
        let result = JSON.parse(res.data.data)[0]
        let most_recent = -1
        for (let [key, value] of Object.entries(result)) {
          if (value !== null) {
            for (let i = 0; i < routes.length-1; i++) {
              if (routes[i].meta.time == key && i > most_recent)
                most_recent = i
            }
          }
        }

        if (most_recent !== -1) {
          let path_to = null
          let cnt = 1
          while (path_to == null) {
            if (routes[most_recent+cnt].meta.time !== null) {
              path_to = routes[most_recent+cnt]
            } else {
              cnt += 1
            }
          }
          Vue.prototype.$toSave = true
          this.$router.push(path_to.path)
        }
      })
    }
  },
  created() {
    if (this.$msg_exit1 == undefined || this.$msg_exit2 == undefined) {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_MessageBoxes',
              columns: ['Variable_name', 'Info/Button_description'],
              match_col: ['Variable_name', 'Variable_name'],
              match_val: ['Button_Exit1', 'Button_Exit2'],
              match_case: ['WHERE', 'OR']
            }
          }
        })
        .then((res) => {
          Vue.prototype.$msg_exit1 = JSON.parse(res.data.data)[0]['Info/Button_description'];
          Vue.prototype.$msg_exit2 = JSON.parse(res.data.data)[1]['Info/Button_description'];
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    }

    if (this.$all_variables == undefined) {
      const path_all = 'fetch_all_from_db'
      axios.get(path_all, {
          params: {
            data: {
              table: 'Table_Variables'
            }
          }
        })
        .then((res) => {
          let result = JSON.parse(res.data.data)
          let isDate = false
          for (let i=0; i < result.length; i++) {
            isDate = moment(result[i]['Variable_Value'], moment.ISO_8601, true).isValid()
            if (isDate) {
              this.all_variables[result[i]['Variable_Name']] = result[i]['Variable_Value']
            } else if (isNaN(parseFloat(result[i]['Variable_Value']))) {
              this.all_variables[result[i]['Variable_Name']] = result[i]['Variable_Value']
            } else {
              this.all_variables[result[i]['Variable_Name']] = parseFloat(result[i]['Variable_Value'])
            }
            if (i == result.length-1) {
              Vue.prototype.$all_variables = this.all_variables
              this.fetched = true
            }
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    }
  }
}