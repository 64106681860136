<template>
  <b-container fluid>
    <div>
      <b-row>
        <div>
          <div class="message_box">
            <idxbox pos = 1 />
          </div>
          <p></p>
          <div class="checkbox_group">
            <h5 style="color: blue">CONSENT TO PARTICIPATE:</h5>
            <p><strong>
              Please tick each of the checkboxes below summarising the essence of the participant information sheet. 
              You need to consent to all aspects in order to participate in the research and play the simulation game:
            </strong></p>
            <b-row>
              <b-row>
              <b-col><input type="checkbox" class="checkbox_input" id="age" value="age" v-model="checked"></b-col>
              <b-col cols="11"><label for="age">I am 18 years old or above.</label></b-col>
              </b-row>
              <b-row>
              <b-col><input type="checkbox" class="checkbox_input" id="voluntary" value="voluntary" v-model="checked"></b-col>
              <b-col cols="11"><label for="voluntary">My participation in this study is entirely voluntary..</label></b-col>
              </b-row>
              <b-row>
              <b-col><input type="checkbox" class="checkbox_input" id="stop" value="stop" v-model="checked"></b-col>
              <b-col cols="11"><label for="stop">I understand that I may stop the simulation game at any time without any negative consequences.</label></b-col>
              </b-row>
              <b-row>
              <b-col><input type="checkbox" class="checkbox_input" id="anonymous" value="anonymous" v-model="checked"></b-col>
              <b-col cols="11"><label for="anonymous">I understand that I will remain anonymous and will not be identifiable in the research data, the results or any findings.</label></b-col>
              </b-row>
              <b-row>
              <b-col><input type="checkbox" class="checkbox_input" id="degree" value="degree" v-model="checked"></b-col>
              <b-col cols="11"><label for="degree">The data I generate as part of the simulation game may be used by Sani Gildenhuys for purposes of obtaining her Doctoral degree at the University of South Africa and for subsequent publishing of journal articles and conference proceedings.</label></b-col>
              </b-row>
              <b-row>
              <b-col><input type="checkbox" class="checkbox_input" id="secure" value="secure" v-model="checked"></b-col>
              <b-col cols="11"><label for="secure">I understand that my data will be treated with utmost care and that it will only be used for research purposes.</label></b-col>
              </b-row>
              <b-row>
              <b-col><input type="checkbox" class="checkbox_input" id="future" value="future" v-model="checked"></b-col>
              <b-col cols="11"><label for="future">I grant permission that my anonymised data may be made available to the research community for possible future research in terms of the open data agenda.</label></b-col>
              </b-row>
              <b-row>
              <b-col><input type="checkbox" class="checkbox_input" id="commit" value="commit" v-model="checked"></b-col>
              <b-col cols="11"><label for="commit">I commit to not do anything that might comprimise the research, such as obtaining input from other people or sources, or providing random values.</label></b-col>
              </b-row>
            </b-row>
          </div>
          <b-row>
            <b-col>
              <div id='id_proceed' style="width: 140px;" :disabled="checked.length == 8">
                <b-button class="proceed" :disabled="checked.length != 8 || !enabled" v-on:click="proceed">Proceed</b-button>
              </div>
              <b-tooltip target="id_proceed" placement="right">You have to click all the checkboxes before you can proceed.</b-tooltip>
            </b-col>
          </b-row>
        </div>
      </b-row>
    </div>
    <exitpanel/>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import idxbox from "@/components/index_box.vue";
import exitpanel from "@/components/exit_panel.vue";
import axios from 'axios';

export default {
  name: "Consent",
  mixins: [common],
  components: {
    exitpanel,
    idxbox
  },
  data () {
    return {
      checked: [],
      player_id: null,
      enabled: false
    }
  },
  methods: {
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    proceed () {
      const path = 'send_to_db';
      axios.get(path, {
          params: {
            table: 'Table_Participants',
            data: {
              Player_ID: [this.player_id],
              Consent: [1]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      axios.get(path, {
          params: {
            table: 'Table_Time',
            data: {
              Player_ID: [this.player_id],
              Consent: [this.getTime()]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
      
      this.$router.push("/participant")
    }
  },
  created() {
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id != 'None') {
        this.enabled = true
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

.checkbox_group {
  border: 2px solid black;
  border-radius: 2px;
  padding: 3px;
  margin-bottom: 12px;
  background-color: #ecffff;
}


.checkbox_input {
  position: inherit;
  outline: 2px solid blue;
  box-shadow: 0 0 0px #ecffff;
  left: 50%;
  margin: 10px;
}


.modal_ethics {
  width: 80%;
}


</style>
