<template>
    <div>
        <iframe height="100%" width=100% :src="filePath" ></iframe>
    </div>
</template>

<script>
export default {
  name: 'PDFJSViewer',
  data() {
    return {
      filePath: 'lib/web/PARTICIPANT INFORMATION SHEET.pdf'
    }
  },
}
</script>
<style scoped>
div {
  width: 100%;
  height: 100vh;
  min-width: 400px;
}
</style>