<template>
  <div>
    <h1>The Money Laundering Simulation Game</h1> 
    <h4><br>WHERE ARE WE:<br></h4>
    <h5>Because the game is part of a research project we first need to:</h5>
    <ul>
      <li><p :class="pos == 1 ? 'position_color' : ''" >Obtain your consent to participate</p></li>
      <li><p :class="pos == 2 ? 'position_color' : ''" >Gather some demographic information</p></li>
      <li><p :class="pos == 3 ? 'position_color' : ''" >Determine your appetite for risk and uncertainty</p></li>
    </ul>
    <h5>Thereafter will follow:</h5>
    <ul>
      <li><p :class="pos == 4 ? 'position_color' : ''" >An introduction to money laundering</p></li>
      <li><p :class="pos == 5 ? 'position_color' : ''" >The objective of the game</p></li>
      <li><p :class="pos == 6 ? 'position_color' : ''" >Familiarising yourself with the game environment</p></li>
      <li><p :class="pos == 7 ? 'position_color' : ''" >The simulation game itself</p></li>
      <li><p :class="pos == 8 ? 'position_color' : ''" >Debriefing</p></li>
      <li><p :class="pos == 9 ? 'position_color' : ''" >Summary of results</p></li>
    </ul>
    <b-button v-show="pos == 1" v-on:click="add_time_view" :disabled="!enabled">Full participant information sheet (optional)</b-button>
  </div>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import axios from 'axios'

export default {
  name: "idxbox",
  mixins: [common],
  props: {
    pos: {
      required: true
    }
  },
  data() {
    return {
      player_id: null,
      enabled: false
    }
  },
  methods: {
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    add_time_view(){
      const path = 'send_to_db';
      axios.get(path, {
          params: {
            table: 'Table_Time',
            data: {
              Player_ID: [this.player_id],
              Ethics_Start: [this.getTime()]
            }
          }
        })
        .then((res) => {
          this.msg = res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
      Vue.prototype.$player_id = this.player_id
      this.$router.push('/ethics')
    }
  },
  created() {
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id != 'None') {
        this.enabled = true
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";


.position_color {
    color: blue;
}

p {
  margin: 0px;
}

</style>
