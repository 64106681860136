import Vue from "vue";
import Router from "vue-router";
import axios from 'axios';
import AliasModal from "@/pages/Authenticate/AliasModal.vue"
import SignIn from "@/pages/Authenticate/SignIn.vue";
import NotFound from "@/pages/Authenticate/NotFound.vue";
import Admin from "@/pages/Authenticate/Admin.vue";
import Debriefing from "@/pages/Debriefing/8.0_Debriefing.vue";
import Goodbye1 from "@/pages/Debriefing/9.1_Goodbye1.vue";
import Goodbye2 from "@/pages/Debriefing/9.2_Goodbye2.vue";
import MLPracticeLevel from "@/pages/MLSimulator/6.2_MLPracticeLevel.vue";
import LaunderingLevels from "@/pages/MLSimulator/7.2_LaunderingLevels.vue";
import Consent from "@/pages/PlayerInfo/1.1_Consent.vue";
import Ethics from "@/pages/PlayerInfo/1.2_Ethics.vue";
import Participant from "@/pages/PlayerInfo/2.0_Participant.vue";
import PracticeAnxietyLevel from "@/pages/PlayerInfo/6.3_PracticeAnxietyLevel.vue";
import Charity from "@/pages/PlayerInfo/3.1_Charity.vue";
import IntroML from "@/pages/PlayerInfo/4.0_IntroML.vue";
import Objective from "@/pages/PlayerInfo/5.0_Objective.vue";
import MLPracIntro from "@/pages/PlayerInfo/6.1_MLPracIntro.vue";
import LaunderingIntro from "@/pages/PlayerInfo/7.1_LaunderingIntro.vue";
import AnxietyLevel from "@/pages/PlayerInfo/7.3_AnxietyLevel.vue";
import AnxietyCompare from "@/pages/PlayerInfo/7.4_AnxietyCompare.vue";
import Game1Intro from "@/pages/Tutorial/3.2_Game1Intro.vue";
import Game1Play from "@/pages/Tutorial/3.3_Game1Play.vue";
import Game1Anxiety from "@/pages/Tutorial/3.4_Game1Anxiety.vue";
import Game1Results from "@/pages/Tutorial/3.5_Game1Results.vue";
import Game2Intro from "@/pages/Tutorial/3.6_Game2Intro.vue";
import Game2Play from "@/pages/Tutorial/3.7_Game2Play.vue";
import Game2Anxiety from "@/pages/Tutorial/3.8_Game2Anxiety.vue";
import Game2Results from "@/pages/Tutorial/3.9_Game2Results.vue";


Vue.use(Router);

Vue.prototype.$toSave = false
const routes = [
  {
    path: "/alias",
    name: "AliasModal",
    component: AliasModal,
    meta: {
      keepAlive: false,
      validTo: [],
      time: null
    }
  },
  {
    path: "/",
    name: "SignIn",
    component: SignIn,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'Consent', 'NotFound', 'Admin'],
      time: 'Access'
    }
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      keepAlive: true,
      validTo: ['SignIn', 'MLPracticeLevel', 'LaunderingIntro'],
      time: null
    }
  },
  {
    path: "/consent",
    name: "Consent",
    component: Consent,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'Ethics', 'Participant'],
      time: 'Consent'
    }
  },
  {
    path: "/ethics",
    name: "Ethics",
    component: Ethics,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'Consent'],
      time: null
    }
  },
  {
    path: "/participant",
    name: "Participant",
    component: Participant,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'Charity'],
      time: 'Demographics'
    }
  },
  {
    path: "/charity",
    name: "Charity",
    component: Charity,
    meta: {
      keepAlive: false,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'Game1Intro'],
      time: 'Charity'
    }
  },
  {
    path: "/game1intro",
    name: "Game1Intro",
    component: Game1Intro,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'Game1Play'],
      time: 'Game1Intro'
    }
  },
  {
    path: "/game1play",
    name: "Game1Play",
    component: Game1Play,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'Game1Anxiety'],
      time: 'Game1Play'
    }
  },
  {
    path: "/game1anxiety",
    name: "Game1Anxiety",
    component: Game1Anxiety,
    meta: {
      keepAlive: false,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'Game1Results'],
      time: 'Game1Anxiety'
    }
  },
  {
    path: "/game1results",
    name: "Game1Results",
    component: Game1Results,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'Game2Intro'],
      time: 'Game1Results'
    }
  },
  {
    path: "/game2intro",
    name: "Game2Intro",
    component: Game2Intro,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'Game2Play'],
      time: 'Game2Intro'
    }
  },
  {
    path: "/game2play",
    name: "Game2Play",
    component: Game2Play,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'Game2Anxiety'],
      time: 'Game2Play'
    }
  },
  {
    path: "/game2anxiety",
    name: "Game2Anxiety",
    component: Game2Anxiety,
    meta: {
      keepAlive: false,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'Game2Results'],
      time: 'Game2Anxiety'
    }
  },
  {
    path: "/game2results",
    name: "Game2Results",
    component: Game2Results,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'IntroML'],
      time: 'Game2Results'
    }
  },
  {
    path: "/introml",
    name: "IntroML",
    component: IntroML,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'Objective'],
      time: 'IntroML'
    }
  },
  {
    path: "/objective",
    name: "Objective",
    component: Objective,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'MLPracIntro'],
      time: 'Objective'
    }
  },
  {
    path: "/mlpracintro",
    name: "MLPracIntro",
    component: MLPracIntro,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'MLPracticeLevel'],
      time: 'MLPracIntro'
    }
  },
  {
    path: "/mlpracticelevel",
    name: "MLPracticeLevel",
    component: MLPracticeLevel,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'PracticeAnxietyLevel', 'LaunderingIntro'],
      time: 'MLPracLevel'
    }
  },
  {
    path: "/practiceanxietylevel",
    name: "PracticeAnxietyLevel",
    component: PracticeAnxietyLevel,
    meta: {
      keepAlive: false,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'MLPracticeLevel'],
      time: null
    }
  },
  {
    path: "/launderingintro",
    name: "LaunderingIntro",
    component: LaunderingIntro,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'LaunderingLevels'],
      time: 'LaunderingIntro'
    }
  },
  {
    path: "/launderinglevels",
    name: "LaunderingLevels",
    component: LaunderingLevels,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'AnxietyLevel', 'AnxietyCompare', 'LaunderingLevels'],
      time: 'Level10_Day_3'
    }
  },
  {
    path: "/anxietylevel",
    name: "AnxietyLevel",
    component: AnxietyLevel,
    meta: {
      keepAlive: false,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'LaunderingLevels'],
      time: null
    }
  },
  {
    path: "/anxietycompare",
    name: "AnxietyCompare",
    component: AnxietyCompare,
    meta: {
      keepAlive: false,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn', 'LaunderingLevels'],
      time: null
    }
  },
  {
    path: "/debriefing",
    name: "Debriefing",
    component: Debriefing,
    meta: {
      keepAlive: true,
      validTo: [
        "SignIn", "Consent", "Ethics", "Participant", "Charity", "Game1Intro", "Game1Play", "Game1Anxiety", "Game1Results", 
        "Game2Intro", "Game2Play", "Game2Anxiety", "Game2Results", "IntroML", "Objective", "MLPracIntro", "MLPracticeLevel", 
        "PracticeAnxietyLevel", "LaunderingIntro", "LaunderingLevels", "AnxietyLevel", "AnxietyCompare"
      ],
      time: null
    }
  },
  {
    path: "/goodbye2",
    name: "Goodbye2",
    component: Goodbye2,
    meta: {
      keepAlive: true,
      validTo: [
        "SignIn", "Consent", "Ethics", "Participant", "Charity", "Game1Intro", "Game1Play", "Game1Anxiety", "Game1Results", 
        "Game2Intro", "Game2Play", "Game2Anxiety", "Game2Results", "IntroML", "Objective", "MLPracIntro", "MLPracticeLevel", 
        "PracticeAnxietyLevel", "LaunderingIntro", "LaunderingLevels", "AnxietyLevel", "AnxietyCompare", "Debriefing"
      ],
      time: null
    }
  },
  {
    path: "/goodbye1",
    name: "Goodbye1",
    component: Goodbye1,
    meta: {
      keepAlive: true,
      validTo: [
        "SignIn", "Consent", "Ethics", "Participant", "Charity", "Game1Intro", "Game1Play", "Game1Anxiety", "Game1Results", 
        "Game2Intro", "Game2Play", "Game2Anxiety", "Game2Results", "IntroML", "Objective", "MLPracIntro", "MLPracticeLevel", 
        "PracticeAnxietyLevel", "LaunderingIntro", "LaunderingLevels", "AnxietyLevel", "AnxietyCompare"
      ],
      time: null
    }
  },
  { 
    path: "/notfound", 
    name: "NotFound", 
    component: NotFound,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn'],
      time: null
    }
  },
  { path: "*", 
    component: NotFound,
    meta: {
      keepAlive: true,
      validTo: ['Goodbye1', 'Goodbye2',  'SignIn'],
      time: null
    } }
];

function getTime() {
  var today = new Date();
  var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  return date+' '+time;
}

var router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})


router.beforeEach((to, from, next) => {
  const IsItABackButton = window.popStateDetected
  window.popStateDetected = false
  if (IsItABackButton && from.name == 'Ethics') {
    if (Vue.prototype.$player_id && Vue.prototype.$player_id !== undefined && Vue.prototype.$player_id !== 'None') {
      const path = 'send_to_db';
      axios.get(path, {
          params: {
            table: 'Table_Time',
            data: {
              Player_ID: [Vue.prototype.$player_id],
              Ethics_End: [getTime()]
            }
          }
        })
        .then((res) => {
          this.msg = res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
      next()
    } else {
      next(false)
    }
  } else if (from.name == 'Admin' && !Vue.prototype.$toSave ) {
    let path_to = routes.filter(function(a) {
      return a.name == to.name
    })[0]
    Vue.prototype.$toSave = true
    path_to.meta.keepAlive = false
    next(path_to)
  } else if ((from.name == 'LaunderingLevels' || from.name == 'MLPracticeLevel') && to.name == 'Admin') {
    next()
  } else if (to.name == 'NotFound') {
    next()
  } else if (Vue.prototype.$toSave) {
    Vue.prototype.$toSave = false
    next()
  } else if ((to.name == 'Goodbye1'|| to.name == 'Goodbye2'|| to.name == 'Debriefing')) {
    next()
  } else if (to.name == 'Admin' && from.name == 'SignIn') {
    next()
  } else if (to.name == 'SignIn' || to.name == 'Admin') {
    let path_to = routes.filter(function(a) {
      return a.name == 'SignIn'
    })
    Vue.prototype.$toSave = true
    next(path_to[0])
  } else if (from.name == undefined) {
    let path_to = routes.filter(function(a) {
      return a.name == 'AliasModal'
    })
    console.log("Path", path_to)
    Vue.prototype.$toSave = true
    next(path_to[0])
  } else if (from.meta.validTo.includes(to.name)) {
    next()
  } else {
    next(false)
  }
})
export default router;
