<template>
  <b-container>
    <b-row>
      <div class="message_box">
        <idxbox pos = 8 />
      </div>
    </b-row>
    <b-row>
      <div class="message_box">
        <p v-html="replace_vars_in_text(info)"></p>
        <b-button :disabled="!timeleft <= 0 || !enabled" class="proceed" style="margin-left: 0px;" v-on:click="proceed">I get it - money laundering is not only illegal, it is really bad!</b-button>
      </div>
    </b-row>
  <p style="color:blue; position:fixed; right:3%; top:3%;">Alias: {{this.$alias}}</p>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import idxbox from "@/components/index_box.vue";
import axios from 'axios';

export default {
  name: "Debriefing",
  mixins: [common],
  components: {
    idxbox
  },
  data() {
    return {
      timeleft: 0,
      player_id: null,
      enabled: false,
      info: ''
    }
  },
  methods: {
    start_timer() {
      this.timeleft = this.$all_variables['Var_DebriefingTime']
      this.timeoutTimer = setInterval(this.set_timeout, 1000);
    },
    set_timeout() {
      if(this.timeleft <= 0){
        clearInterval(this.timeoutTimer);
        this.timeleft += 1
      }
      this.timeleft -= 1;
    },
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    proceed () {
      const path = 'send_to_db';
      axios.get(path, {
          params: {
            table: 'Table_Time',
            data: {
              Player_ID: [this.player_id],
              Debriefing: [this.getTime()]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      this.$router.push("/goodbye2")
    },
    getVariables() {
      const path = 'fetch_from_db';
      axios.get(path, {
          params: {
            data: {
              table: 'Table_MessageBoxes',
              columns: ['Info/Button_description'],
              match_col: ['Variable_name'],
              match_val: ['Info_debriefing'],
              match_case: ['WHERE']
            }
          }
        })
        .then((res) => {
          this.info = JSON.parse(res.data.data)[0]['Info/Button_description'];
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    }
  },
  created() {
    this.getVariables()
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id != 'None') {
        this.start_timer()
        this.enabled = true
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

</style>
