<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
    name: 'MLExperiment',
    created(){
          document.title = "MLExperiment"   
    }
  }
</script>
