var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{staticClass:"exit",attrs:{"disabled":!_vm.enabled},on:{"click":function($event){return _vm.$vm2.open('exit_modal')}}},[_vm._v("Abandon study")]),_c('vue-modal-2',{staticStyle:{"z-index":"10"},attrs:{"name":"exit_modal","modalSize":"md","headerOptions":{
      title: 'Exit?',
    },"footerOptions":{
      btn1: 'No',
      btn2: 'Yes',
      btn2Style: {
        backgroundColor: 'red',
        left: 0,
      },
      btn2OnClick: function () {
        _vm.goodbye();
      },
      btn1OnClick: function () {
        _vm.$vm2.close('exit_modal');
      },
    }},on:{"on-close":function($event){return _vm.$vm2.close('exit_modal')}}},[_c('p',{staticClass:"exit_text",domProps:{"innerHTML":_vm._s(_vm.check_message())}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }