<template>
  <b-container>
    <b-row  style="padding: 10px">
      <div class="message_box">
        <b-row>
          <h3 style="color: blue">LEVEL 1 - DAY 1: Anxiety</h3>
          <h4>While your transactions are being reported (or not) and further investigated…</h4>
            <p style="color: blue">How anxious are you feeling?</p>
            <label for="anxiety">Select the most appropriate description from the dropdown box to indicate how anxious you are currently feeling:</label>
            <Dropdown
              :options="[
                {'id': 1, 'name': '0 - Totally relaxed'},
                {'id': 2, 'name': '1'},
                {'id': 3, 'name': '2'},
                {'id': 4, 'name': '3'},
                {'id': 5, 'name': '4'},
                {'id': 6, 'name': '5'},
                {'id': 7, 'name': '6'},
                {'id': 8, 'name': '7'},
                {'id': 9, 'name': '8'},
                {'id': 10, 'name': '9'},
                {'id': 11, 'name': '10 - Unbearably anxious'}]"
              :maxItem="11"
              placeholder="Select anxiety level"
              v-on:selected="anxiety_option"
              id="anxienty">
            </Dropdown>
          </b-row>
          <b-row>
          <div id='id_proceed' style="width: 10%; min-width: 140px" :disabled="!checkFilled(anxiety_level)">
            <b-button class="proceed" :disabled="checkFilled(anxiety_level) || !enabled" v-on:click="proceed">Proceed</b-button>
          </div>
          <b-tooltip target="id_proceed" placement="right">Please select an anxiety level.</b-tooltip>
        </b-row>
        <b-row>
          <exitpanel :screennum="2"/>
        </b-row>
      </div>
    </b-row>
  <p style="color:blue; position:fixed; right:3%; top:3%;">Alias: {{this.$alias}}</p>
  </b-container>
</template>

<script>
import Vue from 'vue';
import common from '@/mixins/common.js'
import exitpanel from "@/components/exit_panel.vue";
import Dropdown from "@/components/dropdown.vue";
import axios from 'axios';

export default {
  name: "AnxietyLevel",
  mixins: [common],
  components: {
    exitpanel,
    Dropdown
  },
  data() {
    return {
      anxiety_level: null,
      player_id: null,
      player_group: null,
      enabled: false
    }
  },
  methods: {
    anxiety_option(v) {
      this.anxiety_level = v.id-1;
    },
    getTime() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date+' '+time;
    },
    proceed(){
      const path = 'send_to_db';
      axios.get(path, {
          params: {
            table: 'Table_Player_Results',
            data: {
              Player_ID: [this.player_id],
              Group: [this.player_group],
              Level: [1],
              Day: [1],
              Anxiety: [this.anxiety_level]
            }
          }
        })
        .then((res) => {
          res.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      this.$router.go(-1)
    },
    get_player_group() {
      const path_fetch = 'fetch_from_db';
      axios.get(path_fetch, {
          params: {
            data: {
              table: 'Table_Participants',
              columns: ['Group'],
              match_col: ['Player_ID'],
              match_val: [this.player_id],
              match_case: ['WHERE']
            }
          }
        })
        .then((res) => {
          let result = JSON.parse(res.data.data);
          this.player_group = result[0]['Group']
          this.enabled = true
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
      });
    },
    checkFilled(val) {
      return (val !== null && val !== val)
    }
  },
  created() {
    if (this.$player_id == undefined) {
      Vue.prototype.$player_id = this.player_id = this.get_player_id()
    } else {
      this.player_id = this.$player_id
    }
  },
  watch: {
    player_id: function() {
      if (this.player_id && this.player_id != 'None') {
        this.get_player_group()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/_main.scss";

.message_box {
  margin: 50px;
  padding: 50px;
  max-width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.proceed {
  width: 10%;
  margin-left: 0px;
}
</style>
